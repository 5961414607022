<template>
  <span class="btn fs-26 d-flex align-items-center"
        v-if="host && host.id === userId"
        v-tooltip.top="lockInteraction ? 'Unlock Interaction' : 'Lock Interaction'"
        @click="toggleInteraction"
  >
    <i class="zmdi zmdi-lock-outline" v-if="lockInteraction"></i>
    <i class="zmdi zmdi-lock-open" v-else></i>
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('room', {
      lockInteraction: state => state.lockInteraction,
      host: state => state.host,
    }),
    ...mapState('account', {
      userId: state => state.id,
    })
  },
  methods: {
    toggleInteraction() {
      this.$store.commit('room/setRoom', { lockInteraction: !this.lockInteraction })
    },
  }
}
</script>

<style scoped lang="scss">
</style>
