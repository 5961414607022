import { render, staticRenderFns } from "./Explosion.vue?vue&type=template&id=4b9b8d12&scoped=true&"
import script from "./Explosion.vue?vue&type=script&lang=js&"
export * from "./Explosion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b9b8d12",
  null
  
)

export default component.exports