<template>
  <div class="dropup" ref="dropup">
    <div @click="toggle = !toggle">
      <slot></slot>
    </div>
    <transition
        name="menu-transition"
        enter-active-class="animated faster zoomIn"
        leave-active-class="animated faster zoomOut"
    >
      <div class="dropdown-menu show pt-2" v-if="toggle" v-click-outside="onClickOutside">
        <vue-scroll
            ref="scroll"
        >
          <div class="picker mr-2">
            <div v-for="(emojiGroup, category) in emojis" :key="category">
              <span class="text-muted small pl-2">{{ category }}</span>
              <div class="emojis text-center">
                <span class="btn fs-18 p-1" @click="$emit('select', emoji)"
                    v-for="(emoji, emojiName) in emojiGroup"
                    :key="emojiName"
                    :title="emojiName"
                >
                  {{ emoji }}
                </span>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </transition>
  </div>
</template>

<script>
import { emojis } from '@/utils/emoji.utils'

export default {
  data() {
    return {
      emojis,
      toggle: false,
    }
  },
  methods: {
    onClickOutside(e) {
      if (!this.$refs.dropup.contains(e.target)) {
        this.toggle = false
      }
    }
  },
}
</script>

<style scoped lang="scss">
.dropup {
  position: unset;
}
.dropdown-menu {
  background-color: $bc-gray-800;
  padding: 0;
  border: none;
  width: 100%;
}
.picker {
  height: 400px;
}
</style>
