<template>
  <span class="btn fs-26 d-flex align-items-center"
        v-tooltip.top="fullscreen ? 'Exit Full Screen' : 'Full Screen'"
        @click="toggleFullscreen"
  >
    <i class="zmdi zmdi-fullscreen-exit" v-if="fullscreen"></i>
    <i class="zmdi zmdi-fullscreen" v-else></i>
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('room', {
      fullscreen: state => state.fullscreen,
    }),
  },
  methods: {
    toggleFullscreen() {
      this.$store.commit('room/setRoom', { fullscreen: !this.fullscreen })
    },
  }
}
</script>

<style scoped lang="scss">
</style>
