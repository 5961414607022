<template>
  <div id="fullscreen" class="fullscreen" ref="fullscreen">
    <slot></slot>
    <div class="buttons"
         v-if="fullscreen"
    >
      <button type="button" class="btn fs-22"
              @click="toggleInteraction"
              v-if="host && host.id === userId"
      >
        <i class="zmdi zmdi-lock-outline"
           v-if="lockInteraction"
        ></i>
        <i class="zmdi zmdi-lock-open"
           v-else
        ></i>
      </button>
      <button type="button" class="btn fs-22"
              @click="toggleChat"
      >
        <i class="zmdi zmdi-comment"
           v-if="showChat"
        ></i>
        <i class="zmdi zmdi-comment-outline"
           v-else
        ></i>
      </button>
    </div>
    <div class="chat"
         v-if="fullscreen && showChat"
    >
      <FullscreenChat></FullscreenChat>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FullscreenChat from '@/views/room/fullscreen/FullscreenChat'

export default {
  components: {
    FullscreenChat
  },
  computed: {
    ...mapState('room', {
      fullscreen: state => state.fullscreen,
      lockInteraction: state => state.lockInteraction,
      host: state => state.host,
    }),
    ...mapState('account', {
      userId: state => state.id,
    })
  },
  data() {
    return {
      showChat: true
    }
  },
  watch: {
    fullscreen(value) {
      const fullscreenElement = document.fullscreenElement
      const { id: fullscreenElementId } = fullscreenElement || {}
      if (value) {
        this.openFullscreen()
      } else {
        if (fullscreenElementId === 'fullscreen') {
          this.closeFullscreen()
        }
      }
    }
  },
  mounted() {
    document.addEventListener('fullscreenchange', this.onFullscreenchange)
  },
  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.onFullscreenchange)
  },
  methods: {
    onFullscreenchange() {
      const fullscreenElement = document.fullscreenElement
      const { id: fullscreenElementId } = fullscreenElement || {}
      if (fullscreenElement) {
        if (fullscreenElementId === 'fullscreen') {
          this.$store.commit('room/setRoom', { fullscreen: true, theater: false })
        }
      } else {
        this.$store.commit('room/setRoom', { fullscreen: false })
      }
    },
    toggleChat() {
      this.showChat = !this.showChat
    },
    toggleInteraction() {
      this.$store.commit('room/setRoom', { lockInteraction: !this.lockInteraction })
    },
    openFullscreen() {
      const el = this.$refs.fullscreen
      if (el.requestFullscreen) {
        el.requestFullscreen()
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen()
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen()
      }
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.interaction {
  outline: none;
}
.fullscreen {
  position: relative;
}
.chat {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1100;
  max-width: 330px;
}
.buttons {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.4);

}
</style>
