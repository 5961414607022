<template>
  <div class="container-fluid tab-bar">
    <div class="row">
      <div class="col-24 p-0 pb-2 pt-2 tabs">
        <Slider>
          <div class="d-flex">
            <div class="btn tab"
                 :class="{ 'current-tab': currentTabId === 'chat' }"
                 @click="currentTabId='chat'"
            >
              Chat
            </div>
            <div class="btn tab"
                 :class="{ 'current-tab': currentTabId === 'members' }"
                 @click="currentTabId='members'"
            >
              Members
              <span class="badge" ref="memberCountBadge"
                    v-animate:heartBeat
              >{{memberCount}}</span>
            </div>
            <div class="btn tab"
                 :class="{ 'current-tab': currentTabId === 'settings' }"
                 @click="currentTabId='settings'"
            >
              Settings
            </div>
            <div class="btn tab"
                 v-if="owner && userId === owner.id && blacklistCount"
                 :class="{ 'current-tab': currentTabId === 'blacklist' }"
                 @click="currentTabId='blacklist'"
            >
              Blacklist
              <span class="badge" ref="blacklistCountBadge"
                    v-animate:heartBeat
              >{{blacklistCount}}</span>
            </div>
          </div>
        </Slider>
      </div>
    </div>
    <div class="row">
      <div class="col-24 p-0 pt-2 tab-content">
        <template v-if="currentTabId === 'chat'">
          <ChatTab></ChatTab>
        </template>
        <template v-else-if="currentTabId === 'members'">
          <MembersTab></MembersTab>
        </template>
        <template v-else-if="currentTabId === 'settings'">
          <SettingsTab
              @toInvite="currentTabId = 'invite'"
          ></SettingsTab>
        </template>
        <template v-else-if="currentTabId === 'blacklist'">
          <BlacklistTab></BlacklistTab>
        </template>
        <template v-else-if="currentTabId === 'invite'">
          <InviteTab></InviteTab>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import animate from '@/directives/animate'
import Slider from '@/components/common/Slider'
import ChatTab from '@/views/room/tabbar/ChatTab'
import SettingsTab from '@/views/room/tabbar/SettingsTab'
import MembersTab from '@/views/room/tabbar/MembersTab'
import BlacklistTab from '@/views/room/tabbar/BlacklistTab'

export default {
  directives: {
    animate
  },
  components: {
    ChatTab,
    SettingsTab,
    MembersTab,
    BlacklistTab,
    Slider,
  },
  computed: {
    ...mapState('room', {
      owner: state => state.owner,
      memberCount: state => state.memberCount,
      blacklistCount: state => state.blacklistCount,
    }),
    ...mapState('account', {
      userId: state => state.id,
    })
  },
  watch: {
    blacklistCount(value) {
      if (!value && this.currentTabId === 'blacklist') {
        this.currentTabId = 'chat'
      }
      if (value) {
        this.$nextTick(() => {
          this.$refs.blacklistCountBadge.animate()
        })
      }
    },
    memberCount() {
      this.$refs.memberCountBadge.animate()
    }
  },
  data() {
    return {
      currentTabId: 'chat',
    }
  },
}
</script>

<style scoped lang="scss">
.tabs {
  border-bottom: 1px solid $bc-gray-600;
}
.tab {
  &.current-tab {
    background-color: $bc-gray-600;
  }
  white-space: nowrap;
}
.badge {
  color: $bc-gray-000;
  background-color: $bc-purple-300;
}
</style>
