<template>
  <div class="text-center pt-2 pb-2">
    <span class="text-muted small"
          v-tooltip.top="formatDateWithTime(message.createdAt)"
    >{{message.text}}</span>
    <div class="d-flex"
         v-if="message.link && message.link.to && message.link.text"
    >
      <router-link class="btn btn-purple-900 mt-2 ml-auto mr-auto"
                   :to="message.link.to"
      >
        {{message.link.text}}
      </router-link>
    </div>
  </div>
</template>

<script>
import { formatDateWithTime } from '@/utils/time.utils'

export default {
  props: {
    message: Object
  },
  methods: {
    formatDateWithTime,
  }
}
</script>

<style scoped lang="scss">
</style>
