<template>
  <div class="voice-chat">
    <video
        v-for="(consumerSrcObject, producerId) in consumersSrcObjects" :key="producerId"
        :srcObject.prop="consumerSrcObject"
        :ref="producerId"
        @loadeddata="onStreamReady(producerId)"
        :muted="voiceChatDeafened"
    ></video>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('room', {
      voiceChatConnected: state => state.voiceChatConnected,
      voiceChatMicroMuted: state => state.voiceChatMicroMuted,
      voiceChatDeafened: state => state.voiceChatDeafened,
      membersClient: state => state.membersClient,
      voiceChatTurnUrl: state => state.voiceChatTurnUrl,
      voiceChatTurnUser: state => state.voiceChatTurnUser,
      voiceChatTurnPass: state => state.voiceChatTurnPass,
    })
  },
  data() {
    return {
      userMedia: null,
      producerWebRtcPeer: null,
      consumersWebRtcPeers: {},
      consumersSrcObjects: {},
    }
  },
  watch: {
    async voiceChatConnected(value) {
      if (value) {
        await this.init()
      } else {
        await this.destroy()
      }
    },
    voiceChatMicroMuted(value) {
      if (!_.isNil(this.userMedia)) {
        this.userMedia.getAudioTracks().forEach((track) => {
          track.enabled = !value
        })
      }
    },
  },
  async beforeDestroy() {
    await this.destroy()
  },
  methods: {
    async destroy() {
      if (!_.isNil(this.membersClient)) {
        if (this.membersClient.authorized) {
          this.membersClient.send({t: 'voice-chat/leave'})
        }
      }
      await this.destroyProducerWebRtcPeer()
      await this.destroyConsumersWebRtcPeers()
    },
    async destroyProducerWebRtcPeer() {
      if (!_.isNil(this.producerWebRtcPeer)) {
        this.producerWebRtcPeer.close()
        this.producerWebRtcPeer = null
      }
      if (!_.isNil(this.userMedia)) {
        this.userMedia.getTracks().forEach(track => track.stop())
        this.userMedia = null
      }
      if (!_.isNil(this.membersClient)) {
        this.membersClient.removeAllListeners('voice-chat/producer/iceCandidate')
        this.membersClient.removeAllListeners('voice-chat/producer/sdpAnswer')
      }
    },
    async init() {
      await this.initConsumersWebRtcPeers()
      await this.initProducerWebRtcPeer()
    },
    async initProducerWebRtcPeer() {
      await this.destroyProducerWebRtcPeer()
      this.$store.commit('room/setRoom', {
        voiceChatMicroMuted: false,
        voiceChatDeafened: false,
        voiceChatProcessing: true,
      })
      this.producerWebRtcPeer = new RTCPeerConnection({
        iceServers: [
          {
            urls: this.voiceChatTurnUrl,
            username: this.voiceChatTurnUser,
            credential: this.voiceChatTurnPass
          }
        ]
      })
      // add user media
      this.userMedia = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      this.userMedia.getTracks().forEach(track => this.producerWebRtcPeer.addTrack(track, this.userMedia))
      // rtc connection state
      this.producerWebRtcPeer.addEventListener('iceconnectionstatechange', () => {
        switch (this.producerWebRtcPeer.iceConnectionState) {
          case 'failed':
            this.$store.commit('room/setRoom', {
              voiceChatProcessing: false,
              voiceChatConnected: false,
            })
            break
          case 'closed':
            this.$store.commit('room/setRoom', {
              voiceChatProcessing: false,
              voiceChatConnected: false,
            })
            break
          case 'connected':
            this.$store.commit('room/setRoom', {
              voiceChatProcessing: false,
              voiceChatConnected: true,
            })
            break
        }
      })
      // on local icecandidate
      this.producerWebRtcPeer.addEventListener('icecandidate', (event) => {
        if (_.isNil(event.candidate)) {
          return
        }
        this.membersClient.send({
          t: 'voice-chat/producer/iceCandidate',
          d: { candidate: event.candidate }
        })
      })
      // create sdp offer
      const sdpOffer = await this.producerWebRtcPeer.createOffer({
        offerToReceiveAudio: false,
        offerToReceiveVideo: false,
      })
      await this.producerWebRtcPeer.setLocalDescription(sdpOffer)
      // on remote icecandidate
      this.membersClient.on('voice-chat/producer/iceCandidate', (d) => {
        const { candidate } = d
        this.producerWebRtcPeer.addIceCandidate(candidate)
      })
      // on sdp answer
      this.membersClient.on('voice-chat/producer/sdpAnswer', (d) => {
        const { sdpAnswer } = d
        this.producerWebRtcPeer.setRemoteDescription(new RTCSessionDescription({
          type: 'answer',
          sdp: sdpAnswer
        }))
      })
      // send sdp offer
      this.membersClient.send({
        t: 'voice-chat/producer/sdpOffer',
        d: { sdpOffer: sdpOffer.sdp }
      })
    },
    async initConsumersWebRtcPeers() {
      this.membersClient.on('voice-chat/consumer/iceCandidate', (d) => {
        const { candidate, producerId } = d
        const consumerWebRtcPeer = this.consumersWebRtcPeers[producerId]
        if (!_.isNil(consumerWebRtcPeer)) {
          consumerWebRtcPeer.addIceCandidate(candidate)
        }
      })
      this.membersClient.on('voice-chat/consumer/sdpAnswer', (d) => {
        const { sdpAnswer, producerId } = d
        const consumerWebRtcPeer = this.consumersWebRtcPeers[producerId]
        if (!_.isNil(consumerWebRtcPeer)) {
          consumerWebRtcPeer.setRemoteDescription(new RTCSessionDescription({
            type: 'answer',
            sdp: sdpAnswer
          }))
        }
      })
      this.membersClient.on('voice-chat/producers', (d) => {
        const { voiceChatProducers } = d
        // удаляем отключившихся производителей
        _.without(_.keys(this.consumersWebRtcPeers), ...voiceChatProducers).forEach((producerId) => {
          const consumerWebRtcPeer = this.consumersWebRtcPeers[producerId]
          consumerWebRtcPeer.close()
          this.consumersWebRtcPeers = _.omit(this.consumersWebRtcPeers, [producerId])
        })
        _.without(_.keys(this.consumersSrcObjects), ...voiceChatProducers).forEach((producerId) => {
          this.consumersSrcObjects = _.omit(this.consumersSrcObjects, [producerId])
        })
        // инициализируем подключившихся производителей
        voiceChatProducers.forEach(async (producerId) => {
          if (!_.has(this.consumersWebRtcPeers, producerId)) {
            await this.initConsumerWebRtcPeer(producerId)
          }
        })
      })
    },
    async destroyConsumersWebRtcPeers() {
      _.forIn(this.consumersWebRtcPeers, (consumerWebRtcPeer, producerId) => {
        consumerWebRtcPeer.close()
        this.consumersWebRtcPeers = _.omit(this.consumersWebRtcPeers, [producerId])
      })
      _.keys(this.consumersSrcObjects).forEach((producerId) => {
        this.consumersSrcObjects = _.omit(this.consumersSrcObjects, [producerId])
      })
      if (!_.isNil(this.membersClient)) {
        this.membersClient.removeAllListeners('voice-chat/consumer/iceCandidate')
        this.membersClient.removeAllListeners('voice-chat/consumer/sdpAnswer')
        this.membersClient.removeAllListeners('voice-chat/producers')
      }
    },
    async initConsumerWebRtcPeer(producerId) {
      const consumerWebRtcPeer = new RTCPeerConnection({
        iceServers: [
          {
            urls: this.voiceChatTurnUrl,
            username: this.voiceChatTurnUser,
            credential: this.voiceChatTurnPass
          }
        ]
      })
      this.$set(this.consumersWebRtcPeers, producerId, consumerWebRtcPeer)
      // on local icecandidate
      consumerWebRtcPeer.addEventListener('icecandidate', (event) => {
        if (_.isNil(event.candidate)) {
          return
        }
        this.membersClient.send({
          t: 'voice-chat/consumer/iceCandidate',
          d: { candidate: event.candidate, producerId }
        })
      })
      // on track
      consumerWebRtcPeer.addEventListener('track', (event) => {
        this.$set(this.consumersSrcObjects, producerId, event.streams[0])
      })
      // create sdp offer
      const sdpOffer = await consumerWebRtcPeer.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: false,
      })
      await consumerWebRtcPeer.setLocalDescription(sdpOffer)
      // send sdp offer
      this.membersClient.send({
        t: 'voice-chat/consumer/sdpOffer',
        d: { sdpOffer: sdpOffer.sdp, producerId }
      })
    },
    async onStreamReady(producerId) {
      await this.$refs[producerId][0].play()
    },
  }
}
</script>

<style scoped lang="scss">
.voice-chat {
  display: none;
}
</style>
