<template>
  <div>
    <div class="chat">
      <Chat></Chat>
    </div>
    <div class="emojis">
      <Emojis></Emojis>
    </div>
  </div>

</template>

<script>
import Chat from '@/views/room/chat/Chat'
import Emojis from '@/views/room/Emojis'
export default {
  components: {
    Chat,
    Emojis,
  },
}
</script>

<style scoped lang="scss">
.chat {
  height: calc(100vh - 170px);
}
</style>
