<template>
  <div class="members">
    <Loading :isLoading="isLoading"></Loading>
    <vue-scroll
        @handle-scroll="handleScroll"
        ref="scroll"
    >
      <div class="mr-3">
        <div class="member d-flex align-items-center mb-3" v-for="member in members" :key="member.id">
          <Avatar class="avatar-md mr-2"
                  :userId="member.user.id"
                  :avatarVersion="member.user.avatarVersion"
                  :alt="member.user.username"
          ></Avatar>
          <MemberName
              :staff="member.user.staff"
              :userId="member.user.id"
              :premium="member.user.premium"
              :username="member.user.username"
              :tooltip="member.user.username"
          ></MemberName>
          <ProcessingBtn class="btn ml-auto"
                         v-if="enableControls(member)"
                         v-tooltip.top="'Ban'"
                         :onClick="() => banMember(member)"
                         :isProcessing="banProcessing.includes(member.id)"
                         :hideSlotOnProcessing="true"
          >
            <i class="zmdi zmdi-block"></i>
          </ProcessingBtn>
          <ProcessingBtn class="btn"
                         v-if="enableControls(member)"
                         v-tooltip.top="'Kick'"
                         :onClick="() => kickMember(member)"
                         :isProcessing="kickProcessing.includes(member.id)"
                         :hideSlotOnProcessing="true"
          >
            <i class="zmdi zmdi-close"></i>
          </ProcessingBtn>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import config from '@/config'
import Avatar from '@/components/common/Avatar'
import MemberName from '@/views/room/MemberName'
import Loading from '@/components/common/Loading.vue'
import ProcessingBtn from '@/components/common/ProcessingBtn'
import { getApiAuth } from '@/utils/credentials.utils'

const { apiUrl } = config

export default {
  components: {
    Avatar,
    MemberName,
    Loading,
    ProcessingBtn,
  },
  computed: {
    ...mapState('room', {
      roomId: state => state.roomId,
      owner: state => state.owner,
      memberCount: state => state.memberCount,
    }),
    ...mapState('account', {
      userId: state => state.id,
    })
  },
  data() {
    return {
      banProcessing: [],
      kickProcessing: [],
      totalMembers: null,
      members: [],
      limit: 30,
      offset: 0,
      isLoading: false,
    }
  },
  watch: {
    async memberCount() {
      await this.render()
    },
  },
  mounted() {
    this.render()
  },
  methods: {
    enableControls(member) {
      return this.userId === this.owner.id && member.user.id !== this.userId
    },
    resetState() {
      this.members = []
      this.limit = 30
      this.offset = 0
    },
    async renderNext() {
      const response = await this.$http.get(apiUrl + `/rooms/${this.roomId}/members`, {
        auth: getApiAuth(),
        params: {
          limit: this.limit,
          offset: this.offset,
        }
      })
      const { items, total } = response.data.result
      this.members = this.members.concat(items)
      this.totalMembers = total
      this.$store.commit('room/setRoom', { memberCount: total })
    },
    async render() {
      this.isLoading = true
      this.resetState()
      await this.renderNext()
      this.isLoading = false
    },
    async handleScroll(vertical) {
      switch (vertical.process) {
        case 0:
          break
        case 1:
          await this.next()
          break
      }
    },
    async next() {
      if (_.isNil(this.totalMembers) || (this.offset + this.limit) < this.totalMembers) {
        this.offset += this.limit
        await this.renderNext()
      }
    },
    banMember(member) {
      this.$confirm({
        inputs: [
          {
            label: 'Enter ban reason',
            value: '',
            type: 'text',
            name: 'text',
            id: 'reason',
          }
        ],
        callback: async (confirm, { inputValues }) => {
          if (confirm) {
            this.banProcessing.push(member.id)
            const { reason } = inputValues
            await this.$http.post(apiUrl + `/rooms/${this.roomId}/members/${member.id}/ban`, {
              reason
            }, {
              auth: getApiAuth()
            })
            this.members = _.filter(this.members, (m) => m.id !== member.id)
            this.banProcessing.splice(this.banProcessing.indexOf(member.id), 1)
          }
        }
      })
    },
    kickMember(member) {
      this.$confirm({
        inputs: [
          {
            label: 'Enter kick reason',
            value: '',
            type: 'text',
            name: 'text',
            id: 'reason',
          }
        ],
        callback: async (confirm, { inputValues }) => {
          if (confirm) {
            this.kickProcessing.push(member.id)
            const { reason } = inputValues
            await this.$http.post(apiUrl + `/rooms/${this.roomId}/members/${member.id}/kick`, {
              reason
            }, {
              auth: getApiAuth()
            })
            this.members = _.filter(this.members, (m) => m.id !== member.id)
            this.kickProcessing.splice(this.kickProcessing.indexOf(member.id), 1)
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.members {
  height: calc(100vh - 129px);
}
</style>
