import config from '@/config'
import { getWsAuth } from '@/utils/credentials.utils'
import WsClient from '@/clients/ws.client'

const { membersWsUrl } = config

class MembersClient extends WsClient {

  get wsUrl() {
    return membersWsUrl
  }

  get wsUrlDisplay() {
    return '@bearcat/members/ws'
  }

  get credentials() {
    return { roomId: this.roomId, inviteCode: this.inviteCode, ...getWsAuth() }
  }

  constructor({ roomId, inviteCode }) {
    super()
    this.roomId = roomId
    this.inviteCode = inviteCode
    this.on('error', this.onError)
    this.on('banned', this.onBanned)
  }

  setCredentials({ roomId, inviteCode }) {
    this.roomId = roomId
    this.inviteCode = inviteCode
  }

  onError(d) {
    const { error } = d
    switch (error) {
      case 'SocketBanned':
        this.onBanned()
        break
    }
  }

  onBanned() {
    console.error(`${this.wsUrlDisplay} banned`)
    this.authorized = false
  }

}

export default MembersClient
