<template>
  <div class="container-fluid">
    <div class="view pt-4 pb-2">
      <div class="row">
        <div class="col-24">
          <h2 class="title-separator-left">Room is Private</h2>
          <p class="text-muted">
            Oops...
          </p>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-24">
          <button type="button" class="btn btn-block btn-bc btn-purple-900" @click="$router.push({ name: 'HomePage' })">Ok</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.view {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
</style>
