<template>
  <div v-if="count >= countGte">
    <div>
      <slot name="title"></slot>
    </div>
    <div class="mt-3">
      <slot name="count-prefix"></slot>
      {{formatTime(count)}}
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

momentDurationFormatSetup(moment)

export default {
  props: {
    countGte: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      count: 0,
      timer: null,
    }
  },
  watch: {
    count: {
      handler() {
        this.timer = setTimeout(() => {
          this.count++
        }, 1000)
      },
      immediate: true
    }
  },
  beforeDestroy() {
    if (!_.isNil(this.timer)) {
      clearTimeout(this.timer)
    }
  },
  methods: {
    formatTime(seconds) {
      let duration = moment.duration(seconds, 'seconds')
      return duration.format("m [min] : s [sec]")
    }
  }
}
</script>

<style scoped lang="scss">
</style>
