<template>
  <div class="wrapper embed-responsive embed-responsive-16by9">
    <div class="progress d-flex justify-content-center align-items-center" v-if="progress">
      <div class="text-center">
        <vue-ellipse-progress
            :loading="true"
            :legend="false"
            :size="60"
            color="#dbdbdb"
            empty-color="#1a1a1d"
        />
        <div class="mt-3">
          <span>{{progress.text}}</span>
        </div>
        <div class="mt-4">
          <TimeCounter
              v-if="progress.state === 'initializing'"
              :countGte="5"
          >
            <template v-slot:title>
              <span>We are preparing your room it may take a few minutes.</span>
            </template>
            <template v-slot:count-prefix>
              <span>Waiting time</span>
            </template>
          </TimeCounter>
        </div>
        <div class="mt-4"
            v-if="progress.state === 'queuing' && !hasPremium()"
        >
          <span class="text-muted">Want to skip the queue?</span>
          <router-link class="btn btn-block btn-purple-900 mt-3"
                       to="/premium"
          >
            Get Bearcat Premium
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import TimeCounter from '@/components/common/TimeCounter'

export default {
  components: {
    TimeCounter,
  },
  computed: {
    ...mapState('room', {
      progress: state => state.progress,
    }),
    ...mapState('account', {
      premium: state => state.premium,
    })
  },
  methods: {
    hasPremium() {
      const { expiredInSeconds } = this.premium || {}
      return (!_.isNil(expiredInSeconds) && expiredInSeconds > 0)
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  background-color: black;
}
.progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  width: 100%;
  height: 100%;
}
</style>
