<template>
  <div class="typing-indicator">
    <template v-if="text && !fullscreen">
      <small class="text-muted font-italic">
        <Clamp :tooltip="text" :maxLines="1">{{text}}</Clamp>
      </small>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import Clamp from '@/components/common/Clamp'

export default {
  components: {
    Clamp
  },
  computed: {
    ...mapState('room', {
      membersClient: state => state.membersClient,
      fullscreen: state => state.fullscreen,
    }),
    ...mapState('account', {
      userId: state => state.id,
    })
  },
  data() {
    return {
      typings: [],
      text: null,
    }
  },
  mounted() {
    this.membersClient.on('chat/typing', this.onTyping)
    this.membersClient.on('chat', this.onMessage)
  },
  beforeDestroy() {
    if (!_.isNil(this.membersClient)) {
      this.membersClient.off('chat/typing', this.onTyping)
      this.membersClient.off('chat', this.onMessage)
    }
  },
  methods: {
    onMessage(d) {
      const userId = _.get(d, 'user.id')
      if (!_.isNil(userId)) {
        this.typings = _.filter(this.typings, (t) => {
          const predicate = t.user.id !== userId
          if (!predicate) {
            clearTimeout(t.timer)
          }
          return predicate
        })
        this.formatText()
      }
    },
    onTyping(d) {
      if (d.user.id === this.userId) {
        return
      }
      this.typings = _.filter(this.typings, (t) => {
        const predicate = t.user.id !== d.user.id
        if (!predicate) {
          clearTimeout(t.timer)
        }
        return predicate
      })
      this.typings.push({
        ...d,
        timer: setTimeout(() => {
          this.typings = _.filter(this.typings, (t) => t.user.id !== d.user.id)
          this.formatText()
        }, 4000)
      })
      this.formatText()
    },
    formatText() {
      if (this.typings.length) {
        const usernames = _.sortBy(_.map(this.typings, 'user.username'),(username) => username.toLowerCase())
        this.text = usernames.join(', ')
        this.text += usernames.length > 1 ? ' are typing...' : ' is typing...'
      } else {
        this.text = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
