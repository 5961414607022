export default {
  inserted(el) {
    el.setCaretPosition = function (pos) {
      if (this.setSelectionRange) {
        this.focus()
        this.setSelectionRange(pos, pos)
      } else if (this.createTextRange) {
        let range = this.createTextRange()
        range.collapse(true)
        range.moveEnd('character', pos)
        range.moveStart('character', pos)
        range.select()
      }
    }
    el.insertAtCaret = function (text) {
      const value = this.value

      const start = this.selectionStart
      const end = this.selectionEnd

      this.value = value.slice(0, start) + text + value.slice(end)

      this.setCaretPosition(start + text.length)
    }
  }
}
