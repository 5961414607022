<template>
  <div ref="wrapped">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { emojisplosion } from 'emojisplosion'

export default {
  computed: {
    ...mapState('room', {
      membersClient: state => state.membersClient,
    })
  },
  data() {
    return {
      emojiCount: 1,
    }
  },
  mounted() {
    this.membersClient.on('explosion', (d) => {
      const { emoji } = d
      this.explode(emoji)
    })
  },
  methods: {
    explode(emoji) {
      emojisplosion({
        container: this.$refs.wrapped,
        emojiCount: this.emojiCount,
        emojis: [ emoji ],
        physics: {
          gravity: -0.35,
          rotationDeceleration: 0,
          initialVelocities: {
            rotation: {
              max: 0,
              min: 0,
            },
            x: {
              max: -2,
              min: -4,
            },
            y: {
              max: 4,
              min: 2,
            },
          },
          fontSize: {
            min: 24,
            max: 32,
          },
          rotation: {
            max: 0,
            min: 0,
          },
        },
        position: () => {
          let rect = this.$refs.wrapped.getBoundingClientRect()
          return {
            x: rect.left + rect.width,
            y: 0
          }
        },
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
