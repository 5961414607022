<template>
  <Interaction>
    <Inactive>
      <div class="wrapper embed-responsive embed-responsive-16by9">
        <video class="embed-responsive-item" ref="video"
               :srcObject.prop="srcObject"
        ></video>
        <Loading :isLoading="!streamReady" :isFullPage="false" :opacity="0"></Loading>
        <div class="play" v-if="showPlay" @click="play">
          <div class="play-img-block">
            <img src="../../assets/play-button.svg" alt="play"/>
          </div>
        </div>
      </div>
    </Inactive>
  </Interaction>
</template>

<script>
import _ from 'lodash'
import transform from 'sdp-transform'
import { mapState } from 'vuex'
import Loading from '@/components/common/Loading'
import Interaction from '@/views/room/Interaction'
import Inactive from '@/views/room/Inactive'

export default {
  components: {
    Interaction,
    Inactive,
    Loading,
  },
  computed: {
    ...mapState('account', {
      streamAudioVolume: state => state.streamAudioVolume,
      muteStreamAudioVolume: state => state.muteStreamAudioVolume,
    }),
    ...mapState('room', {
      streamBufferSize: state => state.streamBufferSize,
      membersClient: state => state.membersClient,
      showPlay: state => state.showPlay,
      streamReady: state => state.streamReady,
      videoCodec: state => state.videoCodec,
      channelTurnUrl: state => state.channelTurnUrl,
      channelTurnUser: state => state.channelTurnUser,
      channelTurnPass: state => state.channelTurnPass,
    })
  },
  data() {
    return {
      webRtcPeer: null,
      srcObject: null,
    }
  },
  async mounted() {
    this.$refs.video.volume = this.streamAudioVolume
    this.$refs.video.muted = this.muteStreamAudioVolume
    this.$refs.video.addEventListener('loadeddata', this.onStreamReady)
    await this.initStream()
  },
  beforeDestroy() {
    this.$refs.video.removeEventListener('loadeddata', this.onStreamReady)
    this.destroyStream()
  },
  watch: {
    streamBufferSize(value) {
      const [ audioReceiver, videoReceiver ] = this.webRtcPeer.getReceivers()
      audioReceiver.playoutDelayHint = value
      videoReceiver.playoutDelayHint = value
    },
    streamAudioVolume(value) {
      this.$refs.video.volume = value
    },
    muteStreamAudioVolume(value) {
      this.$refs.video.muted = value
    },
    async videoCodec() {
      this.destroyStream()
      await this.initStream()
    }
  },
  methods: {
    destroyStream() {
      if (!_.isNil(this.webRtcPeer)) {
        this.webRtcPeer.close()
        this.webRtcPeer = null
      }
      if (!_.isNil(this.membersClient)) {
        this.membersClient.removeAllListeners('channel/consumer/iceCandidate')
        this.membersClient.removeAllListeners('channel/consumer/sdpAnswer')
      }
    },
    async initStream() {
      console.log('Waiting for stream...')
      this.$store.commit('room/setRoom', { streamReady: false })
      this.webRtcPeer = new RTCPeerConnection({
        iceServers: [
          {
            urls: this.channelTurnUrl,
            username: this.channelTurnUser,
            credential: this.channelTurnPass,
          }
        ]
      })
      // on local icecandidate
      this.webRtcPeer.addEventListener('icecandidate', this.onLocalIceCandidate)
      // on track
      this.webRtcPeer.addEventListener('track', this.onTrack)
      // create sdp offer
      this.webRtcPeer.addTransceiver("video")
      this.webRtcPeer.addTransceiver("audio")
      let sdpOffer = await this.webRtcPeer.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      })
      // transform sdp offer
      try {
        sdpOffer = this.transformSdpOffer(sdpOffer)
      } catch (e) {
        console.error('Sdp Offer Transform Error:', e)
      }
      await this.webRtcPeer.setLocalDescription(sdpOffer)
      // on remote icecandidate
      this.membersClient.on('channel/consumer/iceCandidate', this.onRemoteIceCandidate)
      // on sdp answer
      this.membersClient.once('channel/consumer/sdpAnswer', this.onSdpAnswer)
      // send sdp offer
      this.membersClient.send({
        t: 'channel/consumer/sdpOffer',
        d: { sdpOffer: sdpOffer.sdp }
      })
    },
    onTrack(event) {
      console.log('Track received. Setting up...')
      this.srcObject = event.streams[0]
    },
    onLocalIceCandidate(event) {
      if (_.isNil(event.candidate)) {
        return
      }
      this.membersClient.send({
        t: 'channel/consumer/iceCandidate',
        d: { candidate: event.candidate }
      })
    },
    transformSdpOffer(sdpOffer) {
      let sdp = transform.parse(sdpOffer.sdp)
      sdp.media[0].fmtp[0].config = _.uniq(_.concat(
          sdp.media[0].fmtp[0].config.split(';'),
          [ 'stereo=1' ]
      )).join(';')
      sdpOffer.sdp = transform.write(sdp)
      return sdpOffer
    },
    onSdpAnswer(d) {
      const { sdpAnswer } = d
      this.webRtcPeer.setRemoteDescription(new RTCSessionDescription({
        type: 'answer',
        sdp: sdpAnswer
      }))
    },
    onRemoteIceCandidate(d) {
      const { candidate } = d
      this.webRtcPeer.addIceCandidate(candidate)
    },
    async onStreamReady() {
      console.log('Stream Ready...')
      this.$store.commit('room/setRoom', { streamReady: true, streamBufferSize: 0 })
      try {
        await this.$refs.video.play()
      } catch (e) {
        console.error(e)
        this.$store.commit('room/setRoom', { showPlay: true })
        return
      }
      this.$store.commit('room/setRoom', { showPlay: false })
      this.unlockInteraction()
    },
    play() {
      this.$refs.video.play()
      this.$store.commit('room/setRoom', { showPlay: false })
      this.unlockInteraction()
    },
    unlockInteraction() {
      this.$store.commit('room/setRoom', { lockInteraction: false })
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  background-color: black;
  position: relative;
}
.play {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: black;
}
.play-img-block {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  img {
    width: 80px;
  }
}
</style>
