<template>
  <vue-scroll :ops="ops">
    <slot></slot>
  </vue-scroll>
</template>

<script>
export default {
  data() {
    return {
      ops: {
        vuescroll: {
          mode: 'slide',
          zooming: false,
          scroller: {
            preventDefaultOnMove: false,
          }
        },
        scrollPanel: {
          scrollingY: false,
          scrollingX: true,
        },
        rail: {},
        bar: {
          disable: true,
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
