<template>
  <div>
    <notifications group="messages" position="bottom right" width="330px">
      <template slot="body" slot-scope="props">
        <div class="message mr-2 pl-2 pr-2">
          <template v-if="props.item.data.type === 'basic'">
            <BasicMessage :message="props.item.data"></BasicMessage>
          </template>

          <template v-if="props.item.data.type === 'gif'">
            <GifMessage :message="props.item.data"></GifMessage>
          </template>

          <template v-if="props.item.data.type === 'system'">
            <SystemMessage :message="props.item.data"></SystemMessage>
          </template>
        </div>
      </template>
    </notifications>
    <div class="input-bar mt-2 mb-2 mr-2">
      <InputBar
          @sendText="sendText"
          @sendGif="sendGif"
      ></InputBar>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import InputBar from '@/views/room/chat/InputBar'
import GifMessage from '@/views/room/chat/GifMessage'
import BasicMessage from '@/views/room/chat/BasicMessage'
import SystemMessage from '@/views/room/chat/SystemMessage'

export default {
  components: {
    GifMessage,
    BasicMessage,
    SystemMessage,
    InputBar,
  },
  computed: {
    ...mapState('room', {
      membersClient: state => state.membersClient,
    }),
  },
  mounted() {
    this.membersClient.on('chat', this.onMessage)
  },
  beforeDestroy() {
    if (!_.isNil(this.membersClient)) {
      this.membersClient.off('chat', this.onMessage)
    }
  },
  methods: {
    onMessage(d) {
      this.$notify({
        group: 'messages',
        duration: 10000,
        speed: 1000,
        data: d
      })
    },
    sendText(text) {
      if (text.length) {
        this.membersClient.send({
          t: 'chat',
          d: { text, type: 'basic', userId: this.userId }
        })
      }
    },
    sendGif(gif) {
      const { url, title, height, width } = gif
      this.membersClient.send({
        t: 'chat',
        d: { type: 'gif', userId: this.userId, gif: { url, title, height, width } }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.input-bar {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 22px;
}
.vue-notification-group {
  bottom: 53px !important;
  z-index: unset !important;
}
.message {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
