<template>
  <div class="inactive">
    <slot></slot>
    <div class="overlay" v-if="ready && streamReady && !showPlay && inactive && inactive.state">
      <div class="countdown">
        <VueCountDown
            :time="getTimeoutSeconds(inactive.timeoutAt)"
        >
          <template slot-scope="props">
            <span>Room is inactive, closing in</span>
            <br>
            <span>{{ props.minutes }} minutes, {{ props.seconds }} seconds</span>
          </template>
        </VueCountDown>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import VueCountDown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    VueCountDown
  },
  computed: {
    ...mapState('room', {
      ready: state => state.ready,
      inactive: state => state.inactive,
      showPlay: state => state.showPlay,
      streamReady: state => state.streamReady,
    }),
  },
  methods: {
    getTimeoutSeconds(timeoutAt) {
      const now = moment.utc()
      const then = moment(new Date(timeoutAt))
      const duration = moment.duration(then.diff(now))
      return duration.asMilliseconds()
    }
  }
}
</script>

<style scoped lang="scss">
.inactive {
  position: relative;
  outline: none;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
}
.countdown {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
