<template>
  <div class="pt-2 pb-2">
    <div class="d-flex">
      <div>
        <Avatar class="avatar-md"
                :userId="message.user.id"
                :avatarVersion="message.user.avatarVersion"
                :alt="message.user.username"
        ></Avatar>
      </div>
      <div class="w-100 message-content">
        <div class="d-flex align-items-center">
          <MemberName
              :staff="message.user.staff"
              :userId="message.user.id"
              :premium="message.user.premium"
              :username="message.user.username"
              :tooltip="message.user.username"
          ></MemberName>
          <span class="text-muted small ml-2"
                v-tooltip.top="formatDateWithTime(message.createdAt)"
          >{{formatTime(message.createdAt)}}</span>
        </div>
        <div class="pt-1">
          <span>{{message.text}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/common/Avatar'
import MemberName from '@/views/room/MemberName'
import { formatTime, formatDateWithTime } from '@/utils/time.utils'

export default {
  components: {
    Avatar,
    MemberName,
  },
  props: {
    message: Object
  },
  methods: {
    formatTime,
    formatDateWithTime,
  }
}
</script>

<style scoped lang="scss">
.message-content {
  padding-left: 15px;
}
</style>
