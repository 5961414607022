<template>
  <div>
    <Slider>
      <div class="pt-3 pb-3 d-flex align-items-center">
        <ToggleControl></ToggleControl>
        <ToggleInteraction class="ml-2"></ToggleInteraction>
        <Volume class="ml-auto"
                v-if="ready"
        ></Volume>
        <ToggleFullscreen
            :class="{'ml-2': true, 'ml-auto': !ready}"
        ></ToggleFullscreen>
        <ToggleTheater class="mr-2"></ToggleTheater>
        <div class="divider"></div>
        <ToggleVoiceChat class="ml-2"></ToggleVoiceChat>
      </div>
    </Slider>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from '@/components/common/Slider'
import Volume from '@/views/room/toolbar/Volume'
import ToggleControl from '@/views/room/toolbar/ToggleControl'
import ToggleInteraction from '@/views/room/toolbar/ToggleInteraction'
import ToggleFullscreen from '@/views/room/toolbar/ToggleFullscreen'
import ToggleTheater from '@/views/room/toolbar/ToggleTheater'
import ToggleVoiceChat from '@/views/room/toolbar/ToggleVoiceChat'

export default {
  components: {
    Slider,
    Volume,
    ToggleControl,
    ToggleInteraction,
    ToggleFullscreen,
    ToggleTheater,
    ToggleVoiceChat,
  },
  computed: {
    ...mapState('room', {
      roomId: state => state.roomId,
      ready: state => state.ready,
    })
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.divider {
  border-left: 2px solid $bc-gray-600;
  height: 30px;
}
</style>
