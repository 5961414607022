<template>
  <span class="btn fs-26 d-flex align-items-center"
        v-tooltip.top="theater ? 'Default View' : 'Theater Mode'"
        @click="toggleTheater"
  >
    <i class="zmdi zmdi-crop-7-5" v-if="theater"></i>
    <i class="zmdi zmdi zmdi-crop-5-4" v-else></i>
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('room', {
      theater: state => state.theater,
    }),
  },
  methods: {
    toggleTheater() {
      this.$store.commit('room/setRoom', { theater: !this.theater })
    },
  }
}
</script>

<style scoped lang="scss">
</style>
