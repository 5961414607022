<template>
  <div class="not-selectable"
      v-click-outside="onClickOutside"
  >
    <div
       :class="{ 'dropdown-toggle': !disabled }"
       @click="toggleState"
    >
      <slot name="title"></slot>
    </div>
    <slot name="content" v-if="toggle"></slot>
  </div>
</template>

<script>
export default {
  props: {
    defaultToggle: {
      type: Boolean,
      default: false
    },
    hideOnClickOutside: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      toggle: this.defaultToggle
    }
  },
  methods: {
    toggleState() {
      if (!this.disabled) {
        this.toggle = !this.toggle
      }
    },
    onClickOutside() {
      if (this.hideOnClickOutside && !this.disabled) {
        this.toggle = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
