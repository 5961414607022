<template>
  <Theater>
    <div class="room">
      <AuthorizedHeader></AuthorizedHeader>
      <Layout>
        <vue-scroll>
          <template v-if="error">
            <RoomNotFound v-if="error.e === 'RoomNotFound'"></RoomNotFound>
            <RoomWasClosed v-else-if="error.e === 'RoomWasClosed'"></RoomWasClosed>
            <Kicked v-else-if="error.e === 'Kicked'" :d="error.d"></Kicked>
            <Banned v-else-if="error.e === 'Banned'" :d="error.d"></Banned>
            <PrivateRoom v-else-if="error.e === 'PrivateRoom'"></PrivateRoom>
          </template>
          <template v-else-if="!isLoading">
            <div class="container-fluid">
              <div class="row">
                <div
                    :class="{'browser': true, 'col-xxxl-18 col-lg-17 align-self-center': !theater, 'col-xxxl-19 col-lg-18 align-self-center': theater}"
                >
                  <VoiceChat></VoiceChat>
                  <Fullscreen>
                    <Explosion>
                      <Browser v-if="ready"></Browser>
                      <Progress v-else></Progress>
                    </Explosion>
                  </Fullscreen>
                  <div class="toolbar">
                    <ToolBar></ToolBar>
                  </div>
                </div>
                <div
                    :class="{'side-panel': true, 'col-xxxl-6 col-lg-7': !theater, 'col-xxxl-5 col-lg-6': theater}"
                >
                  <TabBar></TabBar>
                </div>
              </div>
            </div>
          </template>
        </vue-scroll>
      </Layout>
    </div>
  </Theater>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import config from '@/config'
import Theater from '@/views/room/theater/Theater'
import AuthorizedHeader from '@/components/header/AuthorizedHeader'
import Layout from '@/components/sidebar/Layout'
import Browser from '@/views/room/Browser'
import Fullscreen from '@/views/room/fullscreen/Fullscreen'
import Progress from '@/views/room/Progress'
import Explosion from '@/views/room/Explosion'
import ToolBar from '@/views/room/toolbar/ToolBar'
import TabBar from '@/views/room/tabbar/TabBar'
import VoiceChat from '@/views/room/VoiceChat'
import RoomNotFound from '@/views/room/errors/RoomNotFound'
import RoomWasClosed from '@/views/room/errors/RoomWasClosed'
import Kicked from '@/views/room/errors/Kicked'
import Banned from '@/views/room/errors/Banned'
import PrivateRoom from '@/views/room/errors/PrivateRoom'
import MembersClient from '@/clients/members.client'
import {
  playNotificationUpward,
  playNotificationDownward,
  playErrorDouble,
  playErrorQuick,
} from '@/utils/sound.utils'
import { getApiAuth } from '@/utils/credentials.utils'
import { playNotificationSoft } from '@/utils/sound.utils'

const { apiUrl } = config

export default {
  components: {
    Theater,
    AuthorizedHeader,
    Layout,
    Browser,
    Fullscreen,
    Progress,
    Explosion,
    ToolBar,
    TabBar,
    VoiceChat,
    RoomNotFound,
    RoomWasClosed,
    Kicked,
    Banned,
    PrivateRoom,
  },
  computed: {
    ...mapState('room', {
      owner: state => state.owner,
      ready: state => state.ready,
      theater: state => state.theater,
    }),
    ...mapState('account', {
      userId: state => state.id,
    })
  },
  watch: {
    '$route.params.roomId': function () {
      this.destroy()
      this.$nextTick(async () => {
        await this.render()
      })
    },
    '$route.query.inviteCode': function () {
      this.membersClient.setCredentials({
        roomId: this.roomId,
        inviteCode: this.$route.query.inviteCode,
      })
      this.finishLoading()
    }
  },
  data() {
    return {
      roomId: this.$route.params.roomId,
      inviteCode: this.$route.query.inviteCode,
      isLoading: true,
      error: null,
      membersClient: null,
      wasInQueue: null,
    }
  },
  async mounted() {
    await this.render()
  },
  beforeDestroy() {
    this.destroy()
  },
  methods: {
    destroy() {
      if (!_.isNil(this.membersClient)) {
        this.membersClient.disconnect()
      }
      this.$store.commit('room/resetState')
      this.roomId = this.$route.params.roomId
      this.inviteCode = this.$route.query.inviteCode
      this.isLoading = true
      this.error = null
      this.membersClient = null
      this.wasInQueue = null
    },
    async render() {
      try {
        await Promise.all([
          this.initMembersClient(),
        ])
      } catch (e) {
        console.error(e)
        return this.finishLoading(true)
      }
      this.finishLoading()
    },
    initMembersClient() {
      return new Promise((resolve, reject) => {
        this.membersClient = new MembersClient({ roomId: this.roomId, inviteCode: this.inviteCode })
        this.membersClient.on('authorized', async () => {
          await this.fetchRoom()
          resolve()
        })
        this.membersClient.on('error', (d) => {
          const { error, metadata } = d
          switch (error) {
            case 'SocketUnauthorized':
              if (!_.isNil(metadata)) {
                const { e, d } = metadata
                if (!_.isNil(e)) {
                  this.error = { e, d }
                }
              }
              break
          }
          if (_.isNil(this.error)) {
            this.error = { e: error }
          }
          reject(error)
        })
        this.membersClient.on('ready', async (d) => {
          const { ready } = d
          this.$store.commit('room/setRoom', { ready })
          if (ready && this.wasInQueue) {
            await playNotificationSoft()
          }
        })
        this.membersClient.on('inactive', async (d) => {
          const { inactive } = d
          this.$store.commit('room/setRoom', { inactive })
          if (inactive.state) {
            await playErrorDouble()
          }
        })
        this.membersClient.on('joined', async (d) => {
          const { userId, memberCount } = d
          this.$store.commit('room/setRoom', { memberCount })
          if (this.userId !== userId) {
            await playNotificationUpward()
          }
        })
        this.membersClient.on('left', async (d) => {
          const { userId, memberCount } = d
          this.$store.commit('room/setRoom', { memberCount })
          if (this.userId !== userId) {
            await playNotificationDownward()
          }
        })
        this.membersClient.on('kick', async (d) => {
          this.membersClient.disconnect()
          const { reason } = d
          this.error = { e: 'Kicked', d: { reason } }
          await playErrorQuick()
        })
        this.membersClient.on('ban', async (d) => {
          this.membersClient.disconnect()
          const { reason } = d
          this.error = { e: 'Banned', d: { reason } }
          await playErrorQuick()
        })
        this.membersClient.on('blacklistCount', (d) => {
          const { blacklistCount } = d
          this.$store.commit('room/setRoom', { blacklistCount })
        })
        this.membersClient.on('videoCodec', (d) => {
          const { videoCodec } = d
          this.$store.commit('room/setRoom', { videoCodec })
        })
        this.membersClient.on('isPrivate', (d) => {
          const { isPrivate } = d
          this.$store.commit('room/setRoom', { isPrivate })
        })
        this.membersClient.on('inviteCode', (d) => {
          const { inviteCode } = d
          if (this.userId !== this.owner.id) {
            this.$router.push(`/rooms/${this.roomId}?inviteCode=${inviteCode}`)
          }
        })
        this.membersClient.on('joined-voice-chat', async (d) => {
          const { voiceChatParticipantCount } = d
          this.$store.commit('room/setRoom', { voiceChatParticipantCount })
        })
        this.membersClient.on('left-voice-chat', async (d) => {
          const { voiceChatParticipantCount } = d
          this.$store.commit('room/setRoom', { voiceChatParticipantCount })
        })
        this.membersClient.on('closed', () => {
          this.membersClient.disconnect()
          this.error = { e: 'RoomWasClosed' }
        })
        this.membersClient.on('host', (d) => {
          const { host } = d
          this.$store.commit('room/setRoom', { host })
        })
        this.membersClient.on('progress', (d) => {
          const { progress } = d
          this.$store.commit('room/setRoom', { progress })
          if (!_.isNil(progress) && progress.state === 'queuing') {
            this.wasInQueue = true
          }
        })
        this.$store.commit('room/setRoom', {
          roomId: this.roomId,
          membersClient: this.membersClient
        })
      })
    },
    async fetchRoom() {
      const response = await this.$http.get(apiUrl + `/rooms/${this.roomId}`, {
        auth: getApiAuth()
      })
      const {
        host, memberCount, owner, ready, inactive, blacklistCount, voiceChatParticipantCount,
        videoCodec, progress, channel, voiceChat, isPrivate,
      } = response.data.result
      const {
        turnUrl: channelTurnUrl, turnUser: channelTurnUser, turnPass: channelTurnPass
      } = channel || {}
      const {
        turnUrl: voiceChatTurnUrl, turnUser: voiceChatTurnUser, turnPass: voiceChatTurnPass
      } = voiceChat || {}
      this.$store.commit('room/setRoom', {
        host, memberCount, owner, ready, inactive, blacklistCount, voiceChatParticipantCount,
        videoCodec, progress, isPrivate,
        channelTurnUrl, channelTurnUser, channelTurnPass,
        voiceChatTurnUrl, voiceChatTurnUser, voiceChatTurnPass,
      })
      if (!_.isNil(progress) && progress.state === 'queuing') {
        this.wasInQueue = true
      }
    },
    finishLoading(fail=false) {
      if (fail) {
        this.$Progress.fail()
      } else {
        this.$Progress.finish()
      }
      this.$store.commit('loading/finish')
      this.isLoading = false
    }
  },
}
</script>

<style scoped lang="scss">
.room {
  background-color: $bc-gray-900;
}
.browser {
  padding-left: 0;
  padding-right: 0;
  .toolbar {
    padding-left: 15px;
    padding-right: 15px;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 15px;
    padding-right: 15px;
    .toolbar {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.side-panel {
  padding-left: 15px;
  padding-right: 15px;
  @include media-breakpoint-up(lg) {
    padding-left: 0;
  }
}
</style>
