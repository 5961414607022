<template>
  <div class="settings">
    <Loading :isLoading="isLoading"></Loading>
    <vue-scroll>
      <div class="mr-3">
        <form>
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-muted" for="name">Room Name</label>
              <label class="text-muted ml-auto" for="autoGeneratedName">Auto Generated</label>
              <ToggleButton class="ml-2"
                            :value="autoGeneratedName"
                            :disabled="!enableControls()"
                            @input="updateAutoGeneratedName"
                            name="autoGeneratedName"
                            id="autoGeneratedName"
              ></ToggleButton>
            </div>
            <ProcessingInput name="name" id="name"
                             :isProcessing="isNameSubmitProcessing"
                             :isDisabled="autoGeneratedName || !enableControls()"
                             v-model="name"
                             @update="updateName"
            ></ProcessingInput>
          </div>
          <div class="form-group">
            <SelectList id="categoryId"
                        :disabled="!enableControls()"
                        :isProcessing="isCategoryIdProcessing"
                        :items="categories"
                        :allowNull="true"
                        :value="categoryId"
                        @input="updateCategoryId"
            >
              <label class="text-muted" for="categoryId">Category</label>
            </SelectList>
          </div>
          <div class="form-group">
            <SelectList id="videoCodec"
                        :disabled="!enableControls() || !ready"
                        :isProcessing="isVideoCodecProcessing"
                        :items="videoCodecs"
                        :value="videoCodec"
                        @input="updateVideoCodec"
            >
              <label class="text-muted" for="videoCodec">Video Codec</label>
            </SelectList>
          </div>
          <div class="form-group">
            <SelectList id="qualityId"
                        :disabled="!enableControls() || !streamReady"
                        :isProcessing="isQualityIdProcessing"
                        :items="qualities"
                        :value="qualityId"
                        @input="updateQualityId"
            >
              <label class="text-muted" for="qualityId">Stream Quality</label>
            </SelectList>
          </div>
          <div class="form-group">
            <SelectList id="streamBufferSize"
                        :disabled="!streamReady"
                        :items="streamBufferSizes"
                        :value="streamBufferSize"
                        @input="updateStreamBufferSize"
            >
              <label class="text-muted" for="streamBufferSize">Playout Delay</label>
            </SelectList>
          </div>
          <div class="form-group">
            <SelectList id="vpnId"
                        :disabled="!enableControls() || !streamReady"
                        :isProcessing="isVpnIdProcessing"
                        :items="vpns"
                        :allowNull="true"
                        :value="vpnId"
                        @input="updateVpnId"
            >
              <label class="text-muted" for="vpnId">Region</label>
            </SelectList>
          </div>
          <div class="form-group">
            <div class="d-flex align-items-center">
              <label class="text-muted" for="isPrivate">Private</label>
              <ToggleButton class="ml-2"
                            :value="isPrivate"
                            :disabled="!enableControls() || !hasPremium() || !streamReady"
                            @input="updateIsPrivate"
                            name="isPrivate"
                            id="isPrivate"
              ></ToggleButton>
            </div>
          </div>
          <div class="form-group"
               v-if="storedIsPrivate && enableControls() && hasPremium()"
          >
            <label class="text-muted">Share this room with your friends</label>
            <InviteLinkInput
                :isProcessing="isInviteLinkProcessing"
                v-model="inviteLink"
                @update="updateInviteLink"
            ></InviteLinkInput>
          </div>
          <div class="mb-2"
              v-if="enableControls() && !hasPremium()"
          >
            <span class="small text-muted">
            If you'd like private rooms, please upgrade to premium.
            <router-link :to="`/premium`">
              Get Bearcat Premium
            </router-link>
          </span>
          </div>
          <div class="form-group">
            <ProcessingBtn type="button" class="btn btn-bc btn-purple-900"
                           v-if="enableControls()"
                           :isProcessing="isCloseProcessing"
                           :onClick="closeRoom"
            >
              Close Room
            </ProcessingBtn>
          </div>
        </form>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import config from '@/config'
import { getApiAuth } from '@/utils/credentials.utils'
import Loading from '@/components/common/Loading.vue'
import SelectList from '@/components/common/SelectList'
import ProcessingInput from '@/components/common/ProcessingInput'
import ProcessingBtn from '@/components/common/ProcessingBtn'
import ToggleButton from '@/components/common/ToggleButton'
import InviteLinkInput from '@/views/room/tabbar/InviteLinkInput'

const { apiUrl, appUrl } = config

export default {
  components: {
    Loading,
    SelectList,
    ProcessingInput,
    ProcessingBtn,
    ToggleButton,
    InviteLinkInput,
  },
  computed: {
    ...mapState('room', {
      roomId: state => state.roomId,
      ready: state => state.ready,
      streamReady: state => state.streamReady,
      owner: state => state.owner,
      streamBufferSize: state => state.streamBufferSize,
      storedIsPrivate: state => state.isPrivate,
    }),
    ...mapState('account', {
      userId: state => state.id,
      premium: state => state.premium,
    }),
    ...mapState('common', {
      categories: state => state.roomCategories,
      qualities: state => state.roomQualities,
      vpns: state => state.roomVpns,
    }),
  },
  data() {
    return {
      streamBufferSizes: [
        {
          value: 0,
          name: '0ms',
        },
        {
          value: 0.1,
          name: '100ms',
        },
        {
          value: 0.2,
          name: '200ms',
        },
        {
          value: 0.3,
          name: '300ms',
        },
        {
          value: 0.5,
          name: '500ms',
        },
        {
          value: 0.7,
          name: '700ms',
        },
        {
          value: 1,
          name: '1s',
        },
      ],
      isPrivate: false,
      autoGeneratedName: false,
      qualityId: null,
      isCategoryIdProcessing: false,
      categoryId: null,
      vpnId: null,
      isVpnIdProcessing: false,
      isQualityIdProcessing: false,
      name: '',
      isNameSubmitProcessing: false,
      videoCodec: null,
      videoCodecs: [ { value: 'h264', name: 'h264' }, { value: 'vp8', name: 'vp8' } ],
      isVideoCodecProcessing: false,
      isLoading: false,
      isCloseProcessing: false,
      isInviteLinkProcessing: false,
      inviteLink: null,
    }
  },
  async mounted() {
    await this.render()
  },
  methods: {
    enableControls() {
      return this.userId === this.owner.id
    },
    setSettings(d) {
      const { name, autoGeneratedName, videoCodec, qualityId, categoryId, isPrivate, vpnId } = d
      if (!_.isUndefined(name)) {
        this.name = name
      }
      if (!_.isUndefined(autoGeneratedName)) {
        this.autoGeneratedName = autoGeneratedName
      }
      if (!_.isUndefined(videoCodec)) {
        this.videoCodec = videoCodec
      }
      if (!_.isUndefined(qualityId)) {
        this.qualityId = qualityId
      }
      if (!_.isUndefined(categoryId)) {
        this.categoryId = categoryId
      }
      if (!_.isUndefined(isPrivate)) {
        this.isPrivate = isPrivate
      }
      if (!_.isUndefined(vpnId)) {
        this.vpnId = vpnId
      }
    },
    async fetchSettings() {
      const response = await this.$http.get(apiUrl + `/rooms/${this.roomId}/settings`, {
        auth: getApiAuth()
      })
      this.setSettings(response.data.result)
    },
    async fetchCategories() {
      const response = await this.$http.get(apiUrl + '/rooms/categories', {
        auth: getApiAuth()
      })
      const categories = response.data.result
      this.$store.commit('common/setRoomCategories', categories)
    },
    async fetchQualities() {
      const response = await this.$http.get(apiUrl + '/rooms/qualities', {
        auth: getApiAuth()
      })
      const qualities = response.data.result
      this.$store.commit('common/setRoomQualities', qualities)
    },
    async render() {
      this.isLoading = true
      let promises = [ this.fetchSettings() ]
      if (this.enableControls()) {
        promises.push(this.fetchInviteLink())
      }
      await Promise.all(promises)
      this.isLoading = false
      await Promise.all([this.fetchCategories(), this.fetchQualities()])
    },
    async updateName(value) {
      this.name = value
      this.isNameSubmitProcessing = true
      try {
        await this.$http.put(apiUrl + `/rooms/${this.roomId}/settings/name`, {
          name: value
        }, {
          auth: getApiAuth()
        })
      } catch (e) {
        console.error(e)
        this.isNameSubmitProcessing = false
        return
      }
      this.isNameSubmitProcessing = false
    },
    async updateCategoryId(value) {
      this.isCategoryIdProcessing = true
      this.categoryId = value
      await this.$http.put(apiUrl + `/rooms/${this.roomId}/settings/categoryId`, {
        categoryId: value
      }, {
        auth: getApiAuth()
      })
      this.isCategoryIdProcessing = false
    },
    async updateQualityId(value) {
      this.isQualityIdProcessing = true
      this.qualityId = value
      await this.$http.put(apiUrl + `/rooms/${this.roomId}/settings/qualityId`, {
        qualityId: value
      }, {
        auth: getApiAuth()
      })
      this.isQualityIdProcessing = false
    },
    async updateIsPrivate(value) {
      this.isPrivate = value
      await this.$http.put(apiUrl + `/rooms/${this.roomId}/settings/isPrivate`, {
        isPrivate: value
      }, {
        auth: getApiAuth()
      })
    },
    async updateAutoGeneratedName(value) {
      this.autoGeneratedName = value
      await this.$http.put(apiUrl + `/rooms/${this.roomId}/settings/autoGeneratedName`, {
        autoGeneratedName: value
      }, {
        auth: getApiAuth()
      })
    },
    async updateVideoCodec(value) {
      this.isVideoCodecProcessing = true
      this.videoCodec = value
      await this.$http.put(apiUrl + `/rooms/${this.roomId}/settings/videoCodec`, {
        videoCodec: value
      }, {
        auth: getApiAuth()
      })
      this.isVideoCodecProcessing = false
    },
    async updateVpnId(value) {
      this.isVpnIdProcessing = true
      this.vpnId = value
      await this.$http.put(apiUrl + `/rooms/${this.roomId}/settings/vpnId`, {
        vpnId: value
      }, {
        auth: getApiAuth()
      })
      this.isVpnIdProcessing = false
    },
    updateStreamBufferSize(value) {
      this.$store.commit('room/setRoom', { streamBufferSize: value })
    },
    closeRoom() {
      this.$confirm({
        title: 'Are your sure?',
        callback: async (confirm) => {
          if (confirm) {
            this.isCloseProcessing = true
            await this.$http.delete(apiUrl + `/rooms/${this.roomId}`, {
              auth: getApiAuth()
            })
            this.isCloseProcessing = false
          }
        }
      })
    },
    async fetchInviteLink() {
      const response = await this.$http.get(apiUrl + `/rooms/${this.roomId}/inviteLink`, {
        auth: getApiAuth()
      })
      const { inviteCode } = response.data.result
      this.inviteLink = appUrl + `/rooms/${this.roomId}?inviteCode=${inviteCode}`
    },
    async updateInviteLink() {
      this.isInviteLinkProcessing = true
      const response = await this.$http.put(apiUrl + `/rooms/${this.roomId}/inviteLink`, {}, {
        auth: getApiAuth()
      })
      const { inviteCode } = response.data.result
      this.inviteLink = appUrl + `/rooms/${this.roomId}?inviteCode=${inviteCode}`
      this.isInviteLinkProcessing = false
    },
    hasPremium() {
      const { expiredInSeconds } = this.premium || {}
      return (!_.isNil(expiredInSeconds) && expiredInSeconds > 0)
    },
  }
}
</script>

<style scoped lang="scss">
.settings {
  height: calc(100vh - 129px);
}
.to-invite {
  cursor: pointer;
  color: $bc-gray-200;
}
</style>
