<template>
  <div class="d-flex align-items-center">
    <Avatar class="avatar-sm mr-2"
            v-if="host"
            :userId="host.id"
            :avatarVersion="host.avatarVersion"
            :alt="host.username"
    ></Avatar>
    <Username class="mr-3"
              v-if="host"
              :userId="host.id"
              :username="host.username"
    ></Username>
    <ProcessingBtn class="btn btn-gray-600 white-space-nowrap"
                   v-if="host && host.id === userId"
                   :isProcessing="isProcessing"
                   :onClick="releaseControl"
    >
      Release Control
    </ProcessingBtn>
    <ProcessingBtn class="btn btn-gray-600 white-space-nowrap"
                   v-else
                   :isProcessing="isProcessing"
                   :onClick="requestControl"
    >
      Request Control
    </ProcessingBtn>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/config'
import Avatar from '@/components/common/Avatar'
import Username from '@/components/common/Username'
import ProcessingBtn from '@/components/common/ProcessingBtn'
import { getApiAuth } from '@/utils/credentials.utils'

const { apiUrl } = config

export default {
  components: {
    Avatar,
    Username,
    ProcessingBtn
  },
  computed: {
    ...mapState('room', {
      roomId: state => state.roomId,
      host: state => state.host,
    }),
    ...mapState('account', {
      userId: state => state.id,
    })
  },
  data() {
    return {
      isProcessing: false
    }
  },
  methods: {
    async releaseControl() {
      this.isProcessing = true
      const response = await this.$http.post(apiUrl + `/rooms/${this.roomId}/control/release`, {}, {
        auth: getApiAuth()
      })
      const { host } = response.data.result
      this.$store.commit('room/setRoom', { host })
      this.isProcessing = false
    },
    async requestControl() {
      this.isProcessing = true
      const response = await this.$http.post(apiUrl + `/rooms/${this.roomId}/control/request`, {}, {
        auth: getApiAuth()
      })
      const { host } = response.data.result
      this.$store.commit('room/setRoom', { host })
      this.isProcessing = false
    }
  }
}
</script>

<style scoped lang="scss">
.white-space-nowrap {
  white-space: nowrap;
}
</style>
