<template>
  <div class="container-fluid emojis">
    <div class="row">
      <div class="col-24 p-0">
        <div class="d-flex">
          <Slider>
            <div class="d-flex">
              <span class="btn fs-22 p-1"
                    v-for="(emoji, emojiName) in emojis['Frequently used']"
                    :key="emojiName"
                    :title="emojiName"
                    @click="explodeEmoji(emoji)"
              >
                {{emoji}}
              </span>
            </div>
          </Slider>
          <EmojiPicker @select="explodeEmoji">
              <span class="btn fs-22 pt-1 pb-1">
                <i class="zmdi zmdi-more"></i>
              </span>
          </EmojiPicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { emojis } from '@/utils/emoji.utils'
import EmojiPicker from '@/components/common/EmojiPicker'
import Slider from '@/components/common/Slider'

export default {
  components: {
    EmojiPicker,
    Slider,
  },
  computed: {
    ...mapState('room', {
      membersClient: state => state.membersClient,
    })
  },
  data() {
    return {
      emojis,
    }
  },
  methods: {
    explodeEmoji(emoji) {
      if (this.membersClient.authorized) {
        this.membersClient.send({
          t: 'explosion',
          d: { emoji }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
