<template>
  <form autocomplete="off">
    <div class="input-group">
      <input type="text" name="text" id="text" class="form-control text" placeholder="Write your message here..."
             v-caret
             ref="text"
             @input="typing"
      >
      <GifPicker @select="sendGif">
        <button type="button" class="btn gif-btn d-flex"><i class="zmdi zmdi-gif"></i></button>
      </GifPicker>
      <EmojiPicker @select="insertEmoji">
        <button type="button" class="btn emoji-btn"><i class="zmdi zmdi-mood"></i></button>
      </EmojiPicker>
      <button type="submit" class="btn submit-btn"
              @click="sendText"
      ><i class="zmdi zmdi-mail-send"></i></button>
      <div class="typing-indicator">
        <TypingIndicator></TypingIndicator>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash'
import caret from '@/directives/caret'
import EmojiPicker from '@/components/common/EmojiPicker'
import GifPicker from '@/components/common/GifPicker'
import TypingIndicator from '@/views/room/chat/TypingIndicator'
export default {
  components: {
    EmojiPicker,
    GifPicker,
    TypingIndicator,
  },
  directives: {
    caret
  },
  data() {
    return {
      typingTimer: null
    }
  },
  methods: {
    insertEmoji(emoji) {
      this.$refs.text.insertAtCaret(emoji)
    },
    sendText(e) {
      e.preventDefault()
      this.$emit('sendText', this.$refs.text.value)
      this.$refs.text.value = ''
    },
    sendGif(gif) {
      this.$emit('sendGif', gif)
    },
    typing() {
      if (_.isNil(this.typingTimer)) {
        this.$emit('typing')
        this.typingTimer = setTimeout(() => {
          clearTimeout(this.typingTimer)
          this.typingTimer = null
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  padding-right: 0;
}
.btn {
  background: rgba(255, 255, 255, 0.05);
  border: none;
  color: $bc-gray-100;
  border-radius: 0;
  font-size: 21px;
}
.submit-btn {
  border-radius: 0 22px 22px 0;
  &:hover, &:focus, &:active, &:visited {
    &:before {
      border-radius: 0 22px 22px 0 !important;
    }
  }
  &:active:not([disabled]) {
    &:before {
      border-radius: 0 22px 22px 0 !important;
    }
  }
}
.emoji-btn {
  height: 44px;
  padding: 6px;
}
.gif-btn {
  height: 44px;
  padding: 6px;
  font-size: 32px;
}
.typing-indicator {
  position: absolute;
  width: 100%;
  left: 0;
  top: -10px;
  background-color: $bc-gray-900;
}
</style>
