<template>
  <div id="theater" class="theater" ref="theater">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('room', {
      theater: state => state.theater,
    }),
  },
  watch: {
    theater(value) {
      const fullscreenElement = document.fullscreenElement
      const { id: fullscreenElementId } = fullscreenElement || {}
      if (value) {
        this.openFullscreen()
      } else {
        if (fullscreenElementId === 'theater') {
          this.closeFullscreen()
        }
      }
    }
  },
  mounted() {
    document.addEventListener('fullscreenchange', this.onFullscreenchange)
  },
  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.onFullscreenchange)
  },
  methods: {
    openFullscreen() {
      const el = this.$refs.theater
      if (el.requestFullscreen) {
        el.requestFullscreen()
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen()
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen()
      }
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
    onFullscreenchange() {
      const fullscreenElement = document.fullscreenElement
      const { id: fullscreenElementId } = fullscreenElement || {}
      if (fullscreenElement) {
        if (fullscreenElementId === 'theater') {
          this.$store.commit('room/setRoom', { theater: true, fullscreen: false })
        }
      } else {
        this.$store.commit('room/setRoom', { theater: false })
      }
    },
  }
}
</script>

<style scoped lang="scss">
</style>
