<template>
  <div class="chat">
    <div class="chat-messages">
      <Loading :isLoading="isLoading"></Loading>
      <vue-scroll
          @handle-scroll="handleScroll"
          ref="scroll"
      >
        <div class="chat-message mr-3"
             v-for="(message, i) in messages"
             :key="message.id"
             :id="genMessageDomId(message.id)"
        >

          <template v-if="showDaySeparator(i)">
            <div class="text-center">
              <span class="text-muted small">{{formatDate(message.createdAt)}}</span>
            </div>
          </template>

          <template v-if="message.type === 'basic'">
            <BasicMessage :message="message"></BasicMessage>
          </template>

          <template v-if="message.type === 'gif'">
            <GifMessage :message="message"></GifMessage>
          </template>

          <template v-if="message.type === 'system'">
            <SystemMessage :message="message"></SystemMessage>
          </template>

        </div>
      </vue-scroll>
      <div class="scroll-to-bottom"
           v-if="lockAutoScrollToBottom"
      >
        <span class="btn btn-gray-600"
              @click="scrollToBottom"
        >
          <i class="zmdi zmdi-long-arrow-down"></i>
        </span>
      </div>
    </div>
    <div class="chat-input p-0 pt-3 pb-2">
      <InputBar
          @sendText="sendText"
          @sendGif="sendGif"
          @typing="typing"
      ></InputBar>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from '@/config'
import { mapState } from 'vuex'
import Loading from '@/components/common/Loading.vue'
import GifMessage from '@/views/room/chat/GifMessage'
import BasicMessage from '@/views/room/chat/BasicMessage'
import SystemMessage from '@/views/room/chat/SystemMessage'
import InputBar from '@/views/room/chat/InputBar'
import { formatDate } from '@/utils/time.utils'
import { getApiAuth } from '@/utils/credentials.utils'

const { apiUrl } = config

export default {
  components: {
    Loading,
    GifMessage,
    BasicMessage,
    SystemMessage,
    InputBar,
  },
  computed: {
    ...mapState('room', {
      roomId: state => state.roomId,
      membersClient: state => state.membersClient,
    }),
    ...mapState('account', {
      userId: state => state.id,
    })
  },
  data() {
    return {
      isLoading: false,
      lockAutoScrollToBottom: false,
      totalMessages: null,
      messages: [],
      limit: 30,
      offset: 0,
    }
  },
  async mounted() {
    await this.render()
    this.membersClient.on('chat', this.onMessage)
  },
  beforeDestroy() {
    if (!_.isNil(this.membersClient)) {
      this.membersClient.off('chat', this.onMessage)
    }
  },
  methods: {
    async fetchMessages() {
      const response = await this.$http.get(apiUrl + `/rooms/${this.roomId}/chat`, {
        auth: getApiAuth(),
        params: {
          limit: this.limit,
          offset: this.offset,
        }
      })
      const { items, total } = response.data.result
      this.messages.unshift(...items)
      this.totalMessages = total
    },
    resetState() {
      this.messages = []
      this.limit = 30
      this.offset = 0
      this.totalMessages = null
    },
    async render() {
      this.isLoading = true
      this.resetState()
      await this.fetchMessages()
      this.$nextTick(() => {
        this.scrollToBottom()
        this.isLoading = false
      })
    },
    sendText(text) {
      if (text.length) {
        this.membersClient.send({
          t: 'chat',
          d: { text, type: 'basic', userId: this.userId }
        })
      }
    },
    sendGif(gif) {
      const { url, title, height, width } = gif
      this.membersClient.send({
        t: 'chat',
        d: { type: 'gif', userId: this.userId, gif: { url, title, height, width } }
      })
    },
    typing() {
      this.membersClient.send({
        t: 'chat/typing',
        d: {}
      })
    },
    genMessageDomId(messageId) {
      return `msg-${messageId}`
    },
    onMessage(d) {
      this.messages.push(d)
      if (!this.lockAutoScrollToBottom) {
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },
    scrollToBottom() {
      this.$refs.scroll.scrollTo({ y: '100%' }, 0)
    },
    scrollIntoView(domId) {
      const selector = '#' + domId
      this.$refs.scroll.scrollIntoView(selector, 0)
    },
    async handleScroll(vertical) {
      switch (vertical.process) {
        case 0:
          this.lockAutoScrollToBottom = true
          await this.previous()
          break
        case 1:
          this.lockAutoScrollToBottom = false
          break
        default:
          this.lockAutoScrollToBottom = true
      }
    },
    async previous() {
      if (_.isNil(this.totalMessages) || (this.offset + this.limit) < this.totalMessages) {
        const { id: firstMessageId} = this.messages.length ? this.messages[0] : {}
        this.offset += this.limit
        await this.fetchMessages()
        this.$nextTick(() => {
          this.scrollIntoView(this.genMessageDomId(firstMessageId))
        })
      }
    },
    showDaySeparator(i) {
      if (i === 0) {
        return true
      }
      const d1 = new Date(this.messages[i-1].createdAt)
      const d2 = new Date(this.messages[i].createdAt)
      return d2.getFullYear() !== d1.getFullYear() || d2.getMonth() !== d1.getMonth() || d2.getDate() !== d1.getDate()
    },
    formatDate,
  }
}
</script>

<style scoped lang="scss">
.chat-messages {
  position: relative;
  height: calc(100% - 60px);
}
.scroll-to-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
