<template>
  <div class="volume d-flex align-items-center">
    <span class="btn fs-26 d-flex align-items-center" @click="toggleVolume"
          v-tooltip.top="muteStreamAudioVolume ? 'Unmute' : 'Mute'"
    >
      <i class="zmdi zmdi-volume-off" v-if="muteStreamAudioVolume"></i>
      <i class="zmdi zmdi-volume-down" v-else></i>
    </span>
    <RangeInput class="volume-range"
        :rangeMin="volumeMin"
        :rangeMax="volumeMax"
        :rangeStep="volumeStep"
        :value="muteStreamAudioVolume ? 0 : streamAudioVolume"
        @input="updateVolumeValue"
    ></RangeInput>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import config from '@/config'
import RangeInput from '@/components/common/RangeInput'
import { getApiAuth, hasCredentials } from '@/utils/credentials.utils'

const { apiUrl } = config

export default {
  components: {
    RangeInput
  },
  computed: {
    ...mapState('account', {
      streamAudioVolume: state => state.streamAudioVolume,
      muteStreamAudioVolume: state => state.muteStreamAudioVolume,
    }),
    ...mapState('room', {
      roomId: state => state.roomId,
    })
  },
  data() {
    return {
      volumeMin: 0,
      volumeMax: 1,
      volumeStep: 0.05,
      streamAudioVolumeUpdateTimer: null,
      muteStreamAudioVolumeUpdateTimer: null,
    }
  },
  watch: {
    streamAudioVolume(value) {
      if (!_.isNil(this.streamAudioVolumeUpdateTimer)) {
        clearTimeout(this.streamAudioVolumeUpdateTimer)
        this.streamAudioVolumeUpdateTimer = null
      }
      this.streamAudioVolumeUpdateTimer = setTimeout(async () => {
        if (hasCredentials()) {
          await this.$http.put(apiUrl + '/account/streamAudioVolume', {
            streamAudioVolume: value
          }, {
            auth: getApiAuth()
          })
        }
      }, 500)
    },
    muteStreamAudioVolume(value) {
      if (!_.isNil(this.muteStreamAudioVolumeUpdateTimer)) {
        clearTimeout(this.muteStreamAudioVolumeUpdateTimer)
        this.muteStreamAudioVolumeUpdateTimer = null
      }
      this.muteStreamAudioVolumeUpdateTimer = setTimeout(async () => {
        if (hasCredentials()) {
          await this.$http.put(apiUrl + '/account/streamAudioVolume', {
            muteStreamAudioVolume: value
          }, {
            auth: getApiAuth()
          })
        }
      }, 500)
    },
  },
  methods: {
    toggleVolume() {
      this.$store.commit('account/setAccount', { muteStreamAudioVolume: !this.muteStreamAudioVolume })
    },
    updateVolumeValue(value) {
      if (this.muteStreamAudioVolume) {
        this.$store.commit('account/setAccount', { muteStreamAudioVolume: false })
      }
      this.$store.commit('account/setAccount', { streamAudioVolume: value })
    }
  }
}
</script>

<style scoped lang="scss">
.volume-range {
  min-width: 80px;
  max-width: 100px;
}
</style>
