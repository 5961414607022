<template>
  <div class="d-flex align-items-center">
    <ProcessingBtn class="btn toggle-connection-btn"
                   v-tooltip.top="voiceChatConnected ? 'Leave The Voice Chat' : 'Join The Voice Chat'"
                   :onClick="toggleConnection"
                   :hideSlotOnProcessing="true"
                   :isProcessing="voiceChatProcessing"
    >
      <span class="fs-26 d-flex align-items-center">
        <i class="zmdi zmdi-phone-in-talk" v-if="voiceChatConnected"></i>
        <i class="zmdi zmdi-phone" v-else></i>
      </span>
      <span ref="voiceChatParticipantCount" class="badge"
            v-if="voiceChatParticipantCount"
            v-animate:heartBeat
      >{{voiceChatParticipantCount}}</span>
    </ProcessingBtn>
    <span class="btn fs-26 d-flex align-items-center ml-auto"
          v-if="voiceChatConnected && !voiceChatProcessing"
          v-tooltip.top="voiceChatMicroMuted ? 'Unmute The Microphone' : 'Mute The Microphone'"
          @click="toggleMute"
    >
              <i class="zmdi zmdi-mic-off" v-if="voiceChatMicroMuted"></i>
              <i class="zmdi zmdi-mic" v-else></i>
            </span>
    <span class="btn fs-26 d-flex align-items-center" @click="toggleDeafen"
          v-if="voiceChatConnected && !voiceChatProcessing"
          v-tooltip.top="voiceChatDeafened ? 'Undeafen The Voice Chat' : 'Deafen The Voice Chat'"
    >
              <i class="zmdi zmdi-volume-off" v-if="voiceChatDeafened"></i>
              <i class="zmdi zmdi-volume-down" v-else></i>
            </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import animate from '@/directives/animate'
import ProcessingBtn from '@/components/common/ProcessingBtn'

export default {
  directives: {
    animate
  },
  components: {
    ProcessingBtn
  },
  computed: {
    ...mapState('room', {
      voiceChatConnected: state => state.voiceChatConnected,
      voiceChatMicroMuted: state => state.voiceChatMicroMuted,
      voiceChatDeafened: state => state.voiceChatDeafened,
      voiceChatProcessing: state => state.voiceChatProcessing,
      voiceChatParticipantCount: state => state.voiceChatParticipantCount,
    }),
  },
  watch: {
    voiceChatParticipantCount(value) {
      if (value && !this.voiceChatProcessing) {
        this.$nextTick(() => {
          this.$refs.voiceChatParticipantCount.animate()
        })
      }
    }
  },
  methods: {
    toggleConnection() {
      this.$store.commit('room/setRoom', { voiceChatConnected: !this.voiceChatConnected })
    },
    toggleMute() {
      this.$store.commit('room/setRoom', { voiceChatMicroMuted: !this.voiceChatMicroMuted })
    },
    toggleDeafen() {
      this.$store.commit('room/setRoom', { voiceChatDeafened: !this.voiceChatDeafened })
    },
  }
}
</script>

<style scoped lang="scss">
.toggle-connection-btn {
  position: relative;
}
.badge {
  position: absolute;
  right: 0;
  top: 0;
  color: $bc-gray-000 !important;
  background-color: $bc-purple-300;
  font-size: 12px;
}
</style>
