<template>
  <div class="pt-2 pb-2">
    <div class="d-flex">
      <div>
        <Avatar class="avatar-md"
            :userId="message.user.id"
            :avatarVersion="message.user.avatarVersion"
            :alt="message.user.username"
        ></Avatar>
      </div>
      <div class="w-100 message-content">
        <div class="d-flex align-items-center">
          <MemberName
              :staff="message.user.staff"
              :userId="message.user.id"
              :premium="message.user.premium"
              :username="message.user.username"
              :tooltip="message.user.username"
          ></MemberName>
          <span class="text-muted small ml-2"
                v-tooltip.top="formatDateWithTime(message.createdAt)"
          >{{formatTime(message.createdAt)}}</span>
        </div>
        <div class="pt-1" ref="gifBlock">
          <vue-load-image v-if="showGif">
            <template slot="image">
              <img class="img-fluid" :src="message.gif.url" :alt="message.gif.title">
            </template>
            <template slot="preloader">
              <div
                  :style="{height: calcGifHeight(message.gif), width: '100%'}"
              >
                <span class="spinner-border" role="status" aria-hidden="true" style="width: 1rem; height: 1rem;"></span>
              </div>
            </template>
            <template slot="error">
              <div
                  :style="{height: calcGifHeight(message.gif), width: '100%'}"
              >
                <span class="text-muted">Oops... Can not load image 😵</span>
              </div>
            </template>
          </vue-load-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueLoadImage from 'vue-load-image'
import Avatar from '@/components/common/Avatar'
import MemberName from '@/views/room/MemberName'
import { formatTime, formatDateWithTime } from '@/utils/time.utils'

export default {
  components: {
    VueLoadImage,
    Avatar,
    MemberName,
  },
  props: {
    message: Object
  },
  data() {
    return {
      showGif: false
    }
  },
  mounted() {
    this.showGif = true
  },
  methods: {
    calcGifHeight(gif) {
      const gifBlockWidth = this.$refs.gifBlock.clientWidth
      if (gifBlockWidth >= gif.width) {
        return `${gif.height}px`
      }
      const ps = gif.width / gifBlockWidth
      return `${gif.height / ps}px`
    },
    formatTime,
    formatDateWithTime,
  },
}
</script>

<style scoped lang="scss">
.message-content {
  padding-left: 15px;
}
</style>
