<template>
  <div class="members">
    <Loading :isLoading="isLoading"></Loading>
    <vue-scroll
        @handle-scroll="handleScroll"
        ref="scroll"
    >
      <div class="mr-3">
        <div class="member d-flex align-items-center mb-3" v-for="ban in bans" :key="ban.id">
          <Avatar class="avatar-md mr-2"
                  :userId="ban.user.id"
                  :avatarVersion="ban.user.avatarVersion"
                  :alt="ban.user.username"
          ></Avatar>
          <Username
              :userId="ban.user.id"
              :username="ban.user.username"
              :tooltip="ban.user.username"
          ></Username>
          <ProcessingBtn class="btn ml-auto"
                         v-if="enableControls(ban)"
                         v-tooltip.top="'Unban'"
                         :onClick="() => unbanUser(ban)"
                         :isProcessing="unbanProcessing.includes(ban.id)"
                         :hideSlotOnProcessing="true"
          >
            <i class="zmdi zmdi-close"></i>
          </ProcessingBtn>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/config'
import Avatar from '@/components/common/Avatar'
import Username from '@/components/common/Username'
import Loading from '@/components/common/Loading.vue'
import ProcessingBtn from '@/components/common/ProcessingBtn'
import { getApiAuth } from '@/utils/credentials.utils'
import _ from "lodash";

const { apiUrl } = config

export default {
  components: {
    Avatar,
    Username,
    Loading,
    ProcessingBtn,
  },
  computed: {
    ...mapState('room', {
      roomId: state => state.roomId,
      owner: state => state.owner,
      memberCount: state => state.memberCount,
    }),
    ...mapState('account', {
      userId: state => state.id,
    })
  },
  data() {
    return {
      unbanProcessing: [],
      totalBans: null,
      bans: [],
      limit: 30,
      offset: 0,
      isLoading: false,
    }
  },
  watch: {
    async memberCount() {
      await this.render()
    },
  },
  mounted() {
    this.render()
  },
  methods: {
    enableControls(member) {
      return this.userId === this.owner.id && member.user.id !== this.userId
    },
    resetState() {
      this.bans = []
      this.limit = 30
      this.offset = 0
    },
    async renderNext() {
      const response = await this.$http.get(apiUrl + `/rooms/${this.roomId}/blacklist`, {
        auth: getApiAuth(),
        params: {
          limit: this.limit,
          offset: this.offset,
        }
      })
      const { items, total } = response.data.result
      this.bans = this.bans.concat(items)
      this.totalBans = total
    },
    async render() {
      this.isLoading = true
      this.resetState()
      await this.renderNext()
      this.isLoading = false
    },
    async handleScroll(vertical) {
      switch (vertical.process) {
        case 0:
          break
        case 1:
          await this.next()
          break
      }
    },
    async next() {
      if (_.isNil(this.totalBans) || (this.offset + this.limit) < this.totalBans) {
        this.offset += this.limit
        await this.renderNext()
      }
    },
    unbanUser(ban) {
      this.$confirm({
        title: 'Are your sure?',
        callback: async (confirm) => {
          if (confirm) {
            this.unbanProcessing.push(ban.id)
            await this.$http.delete(apiUrl + `/rooms/${this.roomId}/members/${ban.id}/ban`, {
              auth: getApiAuth()
            })
            this.bans = _.filter(this.bans, (b) => b.id !== ban.id)
            this.unbanProcessing.splice(this.unbanProcessing.indexOf(ban.id), 1)
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.members {
  height: calc(100vh - 170px);
}
</style>
