<template>
  <div class="interaction"
       tabindex="0"
       @keydown="onKeyDown"
       @keyup="onKeyUp"
       @mousemove="onMouseMove"
       @mousedown="onMouseDown"
       @mouseup="onMouseUp"
       @wheel="onMouseWheel"
       @contextmenu="onRightClick"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('room', {
      membersClient: state => state.membersClient,
      lockInteraction: state => state.lockInteraction,
      host: state => state.host,
    }),
    ...mapState('account', {
      userId: state => state.id,
    })
  },
  data() {
    return {
      videoWidth: 1280,
      videoHeight: 720,
    }
  },
  methods: {
    onRightClick(e) {
      e.preventDefault()
    },
    onKeyDown(e) {
      e.preventDefault()
      const { key, ctrlKey, shiftKey } = e
      // paste text
      if (ctrlKey && key === 'v') {
        if (this.hasControl()) {
          if (navigator.clipboard && navigator.clipboard.readText) {
            return navigator.clipboard.readText().then(text => this.emitEvent({ text }, 'PASTE_TEXT'))
          } else {
            return this.$confirm({
              inputs: [
                {
                  label: 'Paste Text',
                  value: '',
                  type: 'text',
                  name: 'text',
                  id: 'text',
                }
              ],
              callback: (confirm, { inputValues }) => {
                if (confirm) {
                  const { text } = inputValues
                  this.emitEvent({ text }, 'PASTE_TEXT')
                }
              }
            })
          }
        }
      }
      this.emitEvent({ key, ctrlKey, shiftKey }, 'KEY_DOWN')
    },
    onKeyUp(e) {
      e.preventDefault()
      const { key, ctrlKey, shiftKey } = e
      if (ctrlKey && key === 'v') {
        return
      }
      this.emitEvent({ key, ctrlKey, shiftKey }, 'KEY_UP')
    },
    onMouseMove(e) {
      const { x, y } = this.calculatePos(e)
      this.emitEvent({ x, y }, 'MOUSE_MOVE')
    },
    onMouseDown(e) {
      const { button } = e
      const { x, y } = this.calculatePos(e)
      this.emitEvent({ x, y, button: button + 1 }, 'MOUSE_DOWN')
    },
    onMouseUp(e) {
      const { button } = e
      const { x, y } = this.calculatePos(e)
      this.emitEvent({ x, y, button: button + 1 }, 'MOUSE_UP')
    },
    onMouseWheel(e) {
      e.preventDefault()
      const { deltaY } = e
      this.emitEvent({ scrollUp: deltaY > 0 }, 'MOUSE_SCROLL')
    },
    emitEvent(d, t) {
      if (this.hasControl()) {
        this.membersClient.send({
          t: 'interaction',
          d: { d, t }
        })
      }
    },
    hasControl() {
      if (this.lockInteraction) {
        return false
      }
      if (!this.host || this.userId !== this.host.id) {
        return false
      }
      return true
    },
    calculatePos (event) {
      const x = this.calculateXPos(event)
      const y = this.calculateYPos(event)
      return { x, y }
    },
    calculateXPos (event) {
      const { clientX, srcElement: elem } = event
      const rect = elem.getBoundingClientRect()
      const xPos = clientX - rect.left
      return Math.round(this.videoWidth * (xPos / elem.clientWidth))
    },
    calculateYPos (event) {
      const { clientY, srcElement: elem } = event
      const rect = elem.getBoundingClientRect()
      const yPos = clientY - rect.top
      return Math.round(this.videoHeight * (yPos / elem.clientHeight))
    }
  }
}
</script>

<style scoped lang="scss">
.interaction {
  outline: none;
}
</style>
