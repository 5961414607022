<template>
  <form>
    <div class="input-group">
      <input type="text" class="form-control" ref="input"
             :value="value"
             :name="name"
             :id="id"
             :placeholder="placeholder"
             :disabled="true"
             v-tooltip="{
              content: 'Copied to clipboard',
              show: isCopied,
              trigger: 'manual',
              placement: 'top',
            }"
      >
      <button class="btn" type="button" tabindex="-1"
              v-if="!isProcessing"
              @click="copy"
              v-tooltip.top="'Copy'"
      >
        <i class="zmdi zmdi-copy"></i>
      </button>
      <button class="btn btn-rounded" type="button" tabindex="-1"
              v-if="!isProcessing"
              @click="update"
              v-tooltip.top="'Update'"
      >
        <i class="zmdi zmdi-refresh"></i>
      </button>
      <button class="btn btn-rounded d-flex align-items-center" type="button" tabindex="-1"
              v-if="isProcessing"
              :disabled="true"
      >
        <span class="spinner-border" role="status" aria-hidden="true"
              style="width: 1rem; height: 1rem;"
        ></span>
      </button>
    </div>
  </form>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    isProcessing: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isCopied: false,
      isCopiedTimer: null,
    }
  },
  methods: {
    reset() {
      this.$refs.input.value = this.value
      this.$refs.input.selectionStart = this.value.length
      this.$refs.input.selectionEnd = this.value.length
      this.$refs.input.focus()
    },
    copy(e) {
      if (!_.isNil(e)) {
        e.preventDefault()
      }
      navigator.clipboard.writeText(this.$refs.input.value).then(function() {}, function() {})
      if (!_.isNull(this.isCopiedTimer)) {
        clearTimeout(this.isCopiedTimer)
        this.isCopiedTimer = null
      }
      this.isCopied = true
      this.isCopiedTimer = setTimeout(() => {
        this.isCopied = false
      }, 800)
    },
    update(e) {
      if (!_.isNil(e)) {
        e.preventDefault()
      }
      this.$emit('update')
    },
  }
}
</script>

<style scoped lang="scss">
input {
  padding-right: 0 !important;
  font-size: small !important;
}
.btn {
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 0;
  color: $bc-gray-100;
  font-size: 21px;
}
.btn-rounded {
  border-radius: 0 22px 22px 0;
  &:hover, &:focus, &:active, &:visited {
    &:before {
      border-radius: 0 22px 22px 0 !important;
    }
  }
  &:active:not([disabled]) {
    &:before {
      border-radius: 0 22px 22px 0 !important;
    }
  }
}
</style>
