<template>
  <Username
      :userId="userId"
      :username="username"
      :tooltip="tooltip"
  >
    <span class="d-flex align-items-center ml-2"
          v-if="staff"
          v-tooltip.top="'Staff'"
    ><i class="zmdi zmdi-fire"></i></span>
    <span class="d-flex align-items-center ml-2"
          v-if="hasPremium()"
          v-tooltip.top="'Premium'"
    ><i class="zmdi zmdi-favorite"></i></span>
    <span class="d-flex align-items-center ml-2"
          v-if="owner && owner.id === userId"
          v-tooltip.top="'Owner'"
    ><i class="zmdi zmdi-star"></i></span>
    <span class="d-flex align-items-center ml-2"
          v-else-if="host && host.id === userId"
          v-tooltip.top="'Host'"
    ><i class="zmdi zmdi-star-outline"></i></span>
  </Username>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import Username from '@/components/common/Username'

export default {
  components: {
    Username,
  },
  props: {
    userId: {
      type: String,
      default: null
    },
    staff: {
      type: Boolean,
      default: false
    },
    premium: {
      type: Object,
      default: null
    },
    username: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
  },
  computed: {
    ...mapState('room', {
      owner: state => state.owner,
      host: state => state.host,
    }),
  },
  methods: {
    hasPremium() {
      const { expiredInSeconds } = this.premium || {}
      return (!_.isNil(expiredInSeconds) && expiredInSeconds > 0)
    },
  }
}
</script>

<style scoped lang="scss">
</style>
