<template>
  <div class="dropup" ref="dropup">
    <div @click="toggle = !toggle">
      <slot></slot>
    </div>
    <transition
        name="menu-transition"
        enter-active-class="animated faster zoomIn"
        leave-active-class="animated faster zoomOut"
    >
      <div class="dropdown-menu show" v-if="toggle" v-click-outside="onClickOutside">
        <div class="d-flex p-2">
          <div class="btn tab d-flex align-items-center"
               :class="{ 'current-tab': currentTabId === 'gif' }"
               @click="selectTab('gif')"
          >
            Gif
          </div>
          <div class="btn tab d-flex align-items-center"
               :class="{ 'current-tab': currentTabId === 'sticker' }"
               @click="selectTab('sticker')"
          >
            Sticker
          </div>
          <form class="ml-auto pl-2" autocomplete="off">
            <div class="input-group">
              <input type="text" name="search" id="search" class="form-control search pr-0" placeholder="I'm looking for..."
                     v-model="query"
                     v-focus
              >
              <button type="submit" class="btn submit-btn" tabindex="-1"
                      @click="submitSearch"
              ><i class="zmdi zmdi-search"></i></button>
            </div>
          </form>
        </div>
        <vue-scroll
            @handle-scroll="handleScroll"
            ref="scroll"
        >
          <div class="picker mr-3">
            <div class="container-fluid">
              <div class="row">
                <div class="text-center p-0" :class="'col-' + 24 / colTotal"
                     v-for="colNum in cols" :key="colNum"
                >
                  <div class="btn p-0"
                       v-for="(item, index) in items[colNum]" :key="index"
                       @click="select(item)"
                  >
                    <img :src="item.preview.url" :alt="item.title" class="img-fluid">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </transition>
  </div>
</template>

<script>
import config from '@/config'
import focus from '@/directives/focus'

const { giphyApiKey } = config

export default {
  directives: {
    focus,
  },
  data() {
    return {
      toggle: false,
      currentTabId: 'gif',
      query: '',
      awaitingSearch: false,
      colTotal: 2,
      cols: null,
      items: null,
      limit: 50,
      offset: 0,
    }
  },
  watch: {
    query() {
      if (!this.awaitingSearch) {
        setTimeout(async () => {
          this.resetState()
          await this.renderNext()
          this.awaitingSearch = false
        }, 500)
      }
      this.awaitingSearch = true
    },
  },
  mounted() {
    this.render()
  },
  methods: {
    select(item) {
      this.$emit('select', item)
      this.toggle = false
    },
    async fetchStickers() {
      let response
      if (this.query.length) {
        response = await this.$http.get('https://api.giphy.com/v1/stickers/search', {
          params: {
            api_key: giphyApiKey,
            q: this.query,
            limit: this.limit,
            offset: this.offset,
          }
        })
      } else {
        response = await this.$http.get('https://api.giphy.com/v1/stickers/trending', {
          params: {
            api_key: giphyApiKey,
            limit: this.limit,
            offset: this.offset,
          }
        })
      }

      return response.data.data
    },
    async fetchGifs() {
      let response
      if (this.query.length) {
        response = await this.$http.get('https://api.giphy.com/v1/gifs/search', {
          params: {
            api_key: giphyApiKey,
            q: this.query,
            limit: this.limit,
            offset: this.offset,
          }
        })
      } else {
        response = await this.$http.get('https://api.giphy.com/v1/gifs/trending', {
          params: {
            api_key: giphyApiKey,
            limit: this.limit,
            offset: this.offset,
          }
        })
      }
      return response.data.data
    },
    async fetch() {
      let gifs = []
      if (this.currentTabId === 'gif') {
        gifs = await this.fetchGifs()
      } else if (this.currentTabId === 'sticker') {
        gifs = await this.fetchStickers()
      }
      gifs = gifs.map((gif) => {
        return {
          title: gif.title,
          url: gif.images.downsized.url,
          height: parseFloat(gif.images.downsized.height),
          width: parseFloat(gif.images.downsized.width),
          preview: {
            height: parseFloat(gif.images.fixed_width_downsampled.height),
            width: parseFloat(gif.images.fixed_width_downsampled.width),
            url: gif.images.fixed_width_downsampled.url,
          }
        }
      })
      return gifs
    },
    resetState() {
      this.cols = Array(...Array(this.colTotal)).map((x, i) => i)
      this.items = Array(...Array(this.colTotal)).map(() => [])
      this.limit = 50
      this.offset = 0
    },
    async renderNext() {
      let gifs = await this.fetch()
      let heights = Array(...Array(this.colTotal)).map(() => 0)
      for (const gif of gifs) {
        const lowestCol = heights.indexOf(Math.min(...heights))
        heights[lowestCol] += gif.preview.height
        this.items[lowestCol].push(gif)
      }
    },
    async render() {
      this.resetState()
      await this.renderNext()
    },
    async next() {
      this.offset += this.limit
      await this.renderNext()
    },
    async handleScroll(vertical) {
      if (vertical.process === 1) {
        await this.next()
      }
    },
    async selectTab(tabId) {
      this.currentTabId = tabId
      this.resetState()
      await this.renderNext()
    },
    async submitSearch(e) {
      e.preventDefault()
      this.resetState()
      await this.renderNext()
    },
    onClickOutside(e) {
      if (!this.$refs.dropup.contains(e.target)) {
        this.toggle = false
      }
    },
  }
}
</script>

<style scoped lang="scss">
.dropup {
  position: unset;
}
.dropdown-menu {
  background-color: $bc-gray-800;
  padding: 0;
  border: none;
  width: 100%;
}
.picker {
  height: 400px;
}
.tab {
  &.current-tab {
    background-color: $bc-gray-600;
  }
  white-space: nowrap;
}
.submit-btn {
  background: rgba(255, 255, 255, 0.05);
  border: none;
  color: $bc-gray-100;
  font-size: 21px;
  border-radius: 0 22px 22px 0;
  &:hover, &:focus, &:active, &:visited {
    &:before {
      border-radius: 0 22px 22px 0 !important;
    }
  }
  &:active:not([disabled]) {
    &:before {
      border-radius: 0 22px 22px 0 !important;
    }
  }
}
</style>
