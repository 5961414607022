<template>
  <Collapse
      :disabled="disabled"
  >
    <template slot="title">
      <slot></slot>
      <span class="ml-1"
            v-tooltip.top="tooltip"
      >
        {{name}}
      </span>
      <span class="spinner-border ml-1" v-if="isProcessing" role="status" aria-hidden="true" style="width: 1rem; height: 1rem;"></span>
    </template>
    <template slot="content">
      <span class="btn"
            v-for="(item, index) in items" :key="index"
            @click="onClick(item.value)"
            :class="{ 'current-item': value === item.value }"
            v-tooltip.top="item.tooltip"
      >
        {{item.name}}
      </span>
    </template>
  </Collapse>
</template>

<script>
import _ from 'lodash'
import Collapse from '@/components/common/Collapse'

export default {
  components: {
    Collapse
  },
  props: {
    items: {
      type: Array,
    },
    allowNull: {
      type: Boolean,
      default: false
    },
    isProcessing: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [ String, Number ],
      default: null
    },
  },
  watch: {
    value(value) {
      this.setState(value)
    },
    items() {
      this.setState(this.value)
    }
  },
  mounted() {
    this.setState(this.value)
  },
  data() {
    return {
      name: null,
      tooltip: null,
    }
  },
  methods: {
    setState(value) {
      const { name, tooltip } = this.getItemByValue(value)
      this.name = name
      this.tooltip = tooltip
    },
    getItemByValue(value) {
      return _.find(this.items, { value }) || {}
    },
    onClick(itemValue) {
      if (this.allowNull && this.value === itemValue) {
        this.$emit('input', null)
      } else {
        this.$emit('input', itemValue)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.current-item {
  background-color: $bc-purple-900;
}
</style>
